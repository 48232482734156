.horizontal-intro {
    .intro-wrapper-padding {
        padding: 104px 0px;

        @include media-breakpoint-down(md) {
            padding: 48px 0px;
        }
    }

    .intro-logo-wrapper {
        margin-bottom: 32px;
    }
    .intro-title{
        color: $color-school;
        overflow-wrap: break-word;
    }
    .intro-paragraph img {
        max-width: 100%;
    }
    .intro-paragraph p {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 30px;
        }
        
        a {
            color: $color-school;
        }
    }

    .intro-cta-wrapper {
        gap: 16px;
        @include media-breakpoint-down(md) {
            gap: 12px;
            margin-right: 0px;

            .btn-primary {
                margin-right: 0px !important;
            }
        }
    }


    .hi-title-wrapper {

        @include media-breakpoint-down(md) {
            padding-right: 16px;
        }
    }
    .hi-separator-wrapper {
        .hi-separator {
            border-left: solid $accent 2px;
            height: 100%;
            @include media-breakpoint-down(md) {
                margin-top: 24px;
                margin-bottom: 24px;
                width: 100px;
                height: 2px;
                border-bottom: solid $accent 4px;
                border-left: unset;
                border-radius: 4px;
            }
        }
    }

    .hi-description-wrapper {
        @include media-breakpoint-down(md) {
            border-left: none;
        }
    }
}
