.listing-news {
    min-height: calc(var(--vh, 1vh)* 100 - 560px);

    @include media-breakpoint-down(md) {
        min-height: calc(var(--vh, 1vh) * 100 - 505px);
    }

    .listng-news-loader-wrapper {
        height: 100%;
        width: 100%;
        min-height: calc(var(--vh, 1vh)* 100 - 560px);

        @include media-breakpoint-down(md) {
            min-height: calc(var(--vh, 1vh) * 100 - 505px);
        }

        .spinner-border {
            color: $color-school;
        }
    }

    .listing-news-year {
        width: 220px;
    }

    .listing-news-tags-wrapper {
        scroll-snap-type: x mandatory;

        .label-tag-unselected {
            background-color: $white;
            color: $color-school;
            border: solid $color-school 1px;
        }

        .label-tag-selected {
            background-color: $color-school;
            color: $white;
            border: solid $color-school 1px;
        }
    }

    .news-card {
        border: none;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        transition: .4s;
        cursor: pointer;

        .listing-news-card-img-big {
            height: 274px;
            border-radius: 8px;
            overflow: hidden;
            object-fit: cover;
        }

        .listing-news-card-img-small {
            height: 175px;
            border-radius: 8px;
            overflow: hidden;
            object-fit: cover;
        }

        .listing-news-label-tag {
            top: 12px;
            right: 12px;
        }

        .card-body {
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 24px;

            .card-title {
                color: $color-school;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 56px;
            }

            .card-text {
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 30px;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .card-paragraph {
                height: 120px;
            }

            .listing-news-details-link {
                color: $color-school;
                margin-top: 24px;
                font-size: 1rem;
                font-weight: 700;
                line-height: 16px;

                a.btn-arrow {
                    svg {
                        fill: $color-school;
                        margin-left: 10px;
                        transition: 0.3s;
                    }

                    &:hover {
                        svg {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }

        .listing-news-card-img-big {
            overflow: hidden;
        }

        &:hover {
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);

            img {
                transform: scale(1.1);
            }
        }
    }

    .news-card img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .4s;
    }

    .listing-news-card {
        padding-bottom: 40px;
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);

        @include media-breakpoint-down(md) {
            padding-bottom: 16px;
            // height: 170px;
        }

        .listing-news-card-img {
            width: 100%;
            height: 229px;
            border-radius: 8px;

            @include media-breakpoint-down(md) {
                max-width: 120px;
                height: auto;
                margin-right: 12px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .label-tag {
                top: 0.5rem;
                right: 0.5rem;
            }
        }

        .listing-news-ctegory-cta {
            top: 1rem;
            right: 1rem;
            background-color: $color-school;
            border-radius: 4px;
            padding: 10px;
            color: $white;
        }

        .listing-news-card-date {
            color: $black;

            @include media-breakpoint-down(md) {
                margin-top: 16px;
            }
        }

        .listing-news-card-title {
            color: $color-school;
            margin-top: 24px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                margin-top: 8px;
            }
        }

        .listing-news-card-paragraph {
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 30px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .listing-news-details-link {
            color: $color-school;
            margin-top: 24px;
            font-size: 1rem;
            font-weight: 700;
            line-height: 16px;

            a.btn-arrow {
                svg {
                    fill: $color-school;
                    margin-left: 10px;
                    transition: 0.3s;
                }

                &:hover {
                    svg {
                        margin-left: 16px;
                    }
                }
            }
        }
    }

    .listing-news-card-mobile-wrapper {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    }

    .select2-container {
        width: 226px !important;
        height: 46px;
    }

    .select2-container--default .select2-selection--single {
        height: 100%;
        display: flex;
        align-items: center;

        .select2-selection__arrow {
            height: 100%;

            b {
                display: none !important;
            }
        }

        span[role="presentation"] {
            margin-right: 16px;
            background-image: url('../../../public/images/arrow_down_black.svg');
            background-repeat: no-repeat;
            background-size: 12px 7px;
            background-position: center center;
        }
    }
}

.btn-listing-filter {
    border: 1px solid $color-school;
    color: black;
    border-radius: 8px;
    padding: 12px 16px;
    margin: 0 8px;
    background-color: $white;

    &:hover {
        border: 1px solid $color-school;
        background-color: $color-school-light;
    }

    &.filter-active {
        background-color: $color-school;
        color: $white;
    }
}
