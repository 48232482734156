.pimcore_area_editmode {
    font-family: Arial, serif !important;
    margin-top: 10px;

    .pimcore_editable.pimcore_editable_input, .pimcore_editable.pimcore_editable_wysiwyg, .pimcore_editable.pimcore_editable_textarea{
        margin-bottom: 20px;
    }

    .char-count-wrapper {
        padding: 5px;
        background-color: #dcdcdc;
        border-radius: 10px;
        &.warning{
            background-color: #f3d979;
        }
    }

    .editModeCollapse:not(.show) {
        display: none;
    }

    .btn.btn-editmode {
        background-color: #0d6efd !important;
        color: #ffffff !important;
        cursor: pointer !important;
    }

    // Relation and Relations Pimcore Fix
    .x-box-inner {
        min-height: 40px !important;
        min-width: 300px !important;
        width: 100% !important;
    }

    .x-container, .x-box-target, .x-toolbar, .x-grid-header-ct {
        width: 100% !important;
        height: 100% !important;
    }

    .x-field.x-form-item.x-hbox-form-item {
        width: calc(100% - 80px) !important;
    }

    .x-box-layout-ct {
        //position: relative !important;
        a.x-btn {
            position: absolute !important;
            display: inline-block;
            top: 0 !important;
            left: unset !important;
            right: 0 !important;
        }
    }

    .x-grid-header-ct {
        height: 25px !important;
    }

    .x-toolbar {
        height: 35px !important;
    }

    .x-panel-body {
        top: 68px !important;
        width: 100% !important;
        height: auto !important;
        overflow: unset !important;

        .x-grid-view {
            height: auto !important;
            width: 100% !important;
        }
    }

    .pimcore_editable_relations, .pimcore_editable_relations .x-panel {
        min-height: 300px !important;
    }

    .x-box-target > a:nth-child(2) {
        right: 38px !important;
    }

    .x-grid-item-container, .x-grid-item-container table {
        width: 100% !important;
    }

    // /Relation and Relations Pimcore Fix


    // Table Pimcore Fix
    .pimcore_editable.pimcore_editable_table {

        .x-grid-header-ct {
            height: 38px !important;
            top: 0 !important;
        }

        .x-panel-body {
            top: 38px !important;
            width: 100% !important;
            height: 100% !important;
            overflow: unset !important;
            margin-top: 0 !important;

            .x-grid-view {
                height: auto !important;
                width: unset !important;

                .x-grid-item-container {
                    width: unset !important;
                    margin-top: 0;

                    table {
                        width: unset !important;

                        .x-grid-cell {
                            height: 23px !important;
                            width: 300px !important;
                            min-width: 300px !important;
                            max-width: 300px !important;
                        }
                    }
                }
            }
        }

        .x-box-inner {
            height: 0 !important;
        }

        .x-panel {
            min-height: 300px !important;
        }

        .x-panel-bodyWrap {
            min-height: 400px !important;
            overflow: scroll;
        }

        .x-toolbar {
            z-index: 4 !important;
            height: 38px !important;
        }

        .x-box-target > a {
            width: 32px !important;
        }

        .x-box-target > a:nth-child(1) {
            left: 0 !important;
        }

        .x-box-target > a:nth-child(2) {
            left: 38px !important;
        }

        .x-box-target > a:nth-child(3) {
            left: 76px !important;
        }

        .x-box-target > a:nth-child(4) {
            left: 114px !important;
        }

        .x-box-target > a:nth-child(5) {
            left: 152px !important;
        }
    }

    // /Table Pimcore Fix
}

.pimcore_area_tab, .pimcore_area_quote {
    .pimcore_editable_link .pimcore_edit_link_button {
        position: absolute !important;
        left: 0 !important;
        top: 31px !important;
        width: 32px !important;
        height: 32px !important;
    }

    .pimcore_editable_link .pimcore_open_link_button {
        position: absolute !important;
        left: 32px !important;
        top: 31px !important;
        width: 32px !important;
        height: 32px !important;
    }

    #isp-scrollspy .edit-mode .nav-pills {
        overflow: visible !important;
    }
}
