.section-mobile-logo {
    height: 68px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    &.header-not-fixed {
        margin-top: -68px;
    }
}

.dropdown-toggle.custom-toggle-header::after {
    display: block !important;
}

//.logo img{
//height: 44px;
//width: 176px;
//object-fit: contain;
//}
.dropdown-item:active,
.dropdown-item:hover {
    background-color: #fff !important;
    color: #000 !important;
}

header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;

    #open-popup-link {
        @include media-breakpoint-down(xl) {
            max-width: 60%;
        }
    }

    .dropdown.dropdown-schools {
        width: 424px;
    }

    @include media-breakpoint-up(xl) {
        top: 0;
    }

    @include media-breakpoint-down(xl) {
        bottom: 0;
    }

    .navbar {
        gap: 0 8px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        background-color: $secondary;
        padding: 12px 16px;

        @include media-breakpoint-up(xl) {
            gap: 0 16px;
            height: 100px;
            transition: height 0.4s ease-in;
            padding: 15px 28px 15px 32px;
        }

        .search-section-mobile {
            @include media-breakpoint-down(xl) {
                gap: 12px;
                padding: 16px;
                .btn-primary.btn-school-icon {
                    width: 42px;
                    height: 42px;
                }
            }

            [type="search"]::-webkit-search-cancel-button {
                appearance: none;
            }

            [type="search"]::-webkit-search-decoration {
                appearance: none;
            }
        }

        .label-popup-mobile {
            font-size: 0.875rem;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .navbar-collapse {
            @include media-breakpoint-down(xl) {
                height: calc(100dvh - var(--isp-header-height-mobile));
                //height: calc((var(--vh, 1vh) * 100) - var(--isp-header-height-mobile));
                position: fixed;
                top: 68px;
                left: 0;
                right: 0;
                bottom: 0;
                transform: translate(-100%);
                transition: transform 0.3s ease;
            }

            &.collapse {
                &.show {
                    @include media-breakpoint-down(xl) {
                        background-color: $secondary;
                        overflow: auto;
                        transform: translate(0);
                    }
                }
            }

            &.collapsing {
                @include media-breakpoint-down(xl) {
                    transform: translate(-100%);
                    background-color: $secondary;
                }
            }

            &.flip-open {
                @include media-breakpoint-down(xl) {
                    transform: translate(100%);
                }

                &.collapsing {
                    @include media-breakpoint-down(xl) {
                        transform: translate(100%);
                    }
                }
            }
        }

        ul.navbar-nav {
            @include media-breakpoint-down(xl) {
                position: relative;
            }
            &.first-level {
                gap: 32px;
                padding: 0 16px;

                @include media-breakpoint-up(xl) {
                    flex: 1;
                    gap: 16px;
                }

                @include media-breakpoint-between(xl, 1280px) {
                    padding: 0;
                }
            }

            li.nav-item {
                width: 17%;

                @include media-breakpoint-down(xl) {
                    width: 100%;
                    border-bottom: 2px solid $body-tertiary-bg;
                    padding-bottom: 10px;
                }

                @include media-breakpoint-between(xl, 1280px) {
                    width: 18%;
                }

                .vr {
                    border-width: 2px;
                    height: 100%;
                    transition: height 0.4s;
                }

                a.first-level-name {
                    color: $color-school;
                    line-height: 16px;
                    letter-spacing: 0.32px;
                    white-space: normal;

                    @include media-breakpoint-down(xxl) {
                        white-space: normal;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 1.5rem;
                        line-height: 28px;
                        letter-spacing: 0.48px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    &:after {
                        @include media-breakpoint-down(xl) {
                            content: "";
                            -webkit-mask: url("../../../public/images/arrow-right-color-school.svg")
                                no-repeat 50% 50%;
                            mask: url("../../../public/images/arrow-right-color-school.svg")
                                no-repeat 50% 50%;
                            -webkit-mask-size: cover;
                            mask-size: cover;
                            width: 12px;
                            height: 20px;
                            background-color: var(--isp-color-school);
                            display: inline-block;
                        }
                    }

                    &.show {
                        text-decoration: underline;
                    }

                    &.flip-arrow {
                        &:after {
                            @include media-breakpoint-down(xl) {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .first-level-description {
                    font-size: 0.875rem;
                    line-height: 18px;
                    opacity: 1;
                    height: auto;
                    display: block;
                    transition: opacity 0.3s, height 0.3s;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;

                    @include media-breakpoint-down(xxl) {
                        display: none;
                    }

                    @include media-breakpoint-down(xl) {
                        display: block;
                        font-size: 1.125rem;
                        line-height: 30px;
                    }
                }

                .vr.vr-first-level {
                    border: 2px solid $body-tertiary-bg;
                    border-radius: 12px;
                }

                .vr.vr-first-level:has(+ div a.show) {
                    border-color: $accent;
                }

                &:hover,
                &.first-level-active {
                    .vr.vr-first-level {
                        border-color: $accent;
                        height: 100%;
                    }

                    .first-level-name {
                        text-decoration: underline;
                    }
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }
        }

        .dropdown-menu.dropdown-menu-custom {
            border-radius: 0;

            @include media-breakpoint-down(xl) {
                position: fixed;
                top: 0;
                bottom: 0;
                -ms-transform: translate(#{if($isRTL, 99%, -99%)});
                -webkit-transform: translate(#{if($isRTL, 99%, -99%)});
                transform: translate(#{if($isRTL, 99%, -99%)});
                width: 100%;
                height: 100%;
            }

            &.show {
                right: 0;
                box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.05);

                @include media-breakpoint-down(xl) {
                    right: unset;
                    overflow-y: auto;
                    overflow-x: hidden;
                    box-shadow: unset;
                    animation: transitionLeftToRight 0.3s forwards;
                }

                .row {
                    @include media-breakpoint-down(xl) {
                        flex-direction: column-reverse;
                    }
                }

                .wrapper-image {
                    max-width: 715px;
                    max-height: 460px;
                    height: 100%;

                    @include media-breakpoint-down(xl) {
                        max-width: unset;
                        max-height: unset;
                        //height: 310px;
                        //width: 410px;
                    }

                    picture {
                        @include media-breakpoint-down(xl) {
                            width: 60%;
                            max-width: 500px;
                        }
                    }

                    img {
                        -webkit-mask-image: url("../../../public/images/mask-semi-circle.png");
                        mask-image: url("../../../public/images/mask-semi-circle.png");
                        -webkit-mask-position: center;
                        mask-position: center;
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        -webkit-mask-size: 100% 100%;
                        mask-size: 100% 100%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        @include media-breakpoint-down(xl) {
                            -webkit-mask-image: url("../../../public/images/mask-semi-angle.png");
                            mask-image: url("../../../public/images/mask-semi-angle.png");
                            -webkit-mask-position: 100% center;
                            mask-position: 100% center;
                            -webkit-mask-repeat: no-repeat;
                            mask-repeat: no-repeat;
                            -webkit-mask-size: 100% 100%;
                            mask-size: 100% 100%;
                            aspect-ratio: 68/85;
                            object-fit: cover;
                        }
                    }
                }

                .dropdown-menu-section {
                    padding-top: 32px;
                    padding-bottom: 20px;

                    .intro-level {
                        .intro-level-only-arrow {
                            &::before {
                                @include media-breakpoint-down(xl) {
                                    content: "";
                                    -webkit-mask: url("../../../public/images/arrow-left-primary.svg")
                                        no-repeat 50% 50%;
                                    mask: url("../../../public/images/arrow-left-primary.svg")
                                        no-repeat 50% 50%;
                                    -webkit-mask-size: cover;
                                    mask-size: cover;
                                    width: 12px;
                                    height: 20px;
                                    margin-right: 16px;
                                    background-color: var(--isp-color-school);
                                    display: inline-block;
                                }
                            }
                        }

                        .intro-level-title {
                            font-size: 1.5rem;
                            font-style: normal;
                            line-height: 28px;
                            letter-spacing: 0.48px;
                        }

                        .intro-level-description {
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                        }
                    }

                    .dropdown-menu-list {
                        li {
                            margin: 15px 0;

                            .vr {
                                border: 2px solid $white;
                                border-radius: 12px;
                                height: auto;
                            }

                            &:hover {
                                .vr {
                                    border-color: $accent;
                                }
                            }

                            .dropdown-item {
                                font-size: 1.25rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 32px;
                                /* 160% */

                                text-decoration-line: underline;

                                &:hover {
                                    background-color: transparent;
                                    font-weight: 700;
                                }

                                &:focus,
                                &:focus-visible {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }

        .megamenu-fixed-section {
            background: rgba(225, 228, 225, 0.2);
        }

        .dropdown-menu-list-fixed {
            @include media-breakpoint-down(xl) {
                padding: 16px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto auto;
            }

            li {
                a.dropdown-item-fixed.dropdown-item {
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    width: fit-content;

                    &:hover {
                        color: $color-school !important;
                        font-weight: 700;
                        background-color: unset !important;
                    }

                    &:focus,
                    &:focus-visible {
                        color: $color-school !important;
                        background-color: unset !important;
                    }
                }
            }
        }

        .portal-link-section {
            ul {
                li {
                    a.dropdown-item {
                        font-size: 1rem;
                        font-style: normal;
                        line-height: 16px;
                        letter-spacing: 0.32px;
                        color: $color-school;
                        width: fit-content;

                        &:hover {
                            background-color: unset !important;
                            color: $color-school !important;
                        }

                        &:focus,
                        &:focus-visible {
                            background-color: unset !important;
                            color: $color-school !important;
                        }
                    }
                }
            }
            .btn-only-arrow {
                position: relative;
                padding-right: 24px;
                padding-left: 24px;
                &:after {
                    position: absolute;
                }
            }
        }

        .header-icon-section {
            gap: 16px;
        }

        .navbar-toggler {
            &:active {
                background: $color-school;
            }

            &:focus {
                box-shadow: unset;
            }

            &:focus-visible {
                border-color: var(--bs-primary);
                box-shadow: 0 0 0 0.1rem var(--bs-primary);
                opacity: 0.95;
            }

            .navbar-toggler-icon {
                background-image: url("../../../public/images/navbar-toggler-white.svg");
            }

            &[aria-expanded="true"] {
                .navbar-toggler-icon {
                    background-image: url("../../../public/images/close-white.svg");
                }
            }
        }
    }

    &.hide-description {
        .navbar {
            height: 80px;

            ul.navbar-nav {
                li.nav-item {
                    .vr.vr-first-level {
                        min-height: 18px;
                    }

                    .first-level-description {
                        opacity: 0;
                        height: 0;
                        display: none;
                    }
                }
            }
        }
    }

    .dropdown-portal-links {
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            right: -16px !important;
            top: 40px;
            width: fit-content;

            &:before {
                content: "";
                background-color: $color-school-light;
                width: 20px;
                height: 20px;
                transform: rotate(-45deg);
                position: absolute;
                right: 16.716px;
                top: -8px;
            }

            a {
                white-space: nowrap;
                cursor: pointer;

                &.btn-only-arrow {
                    &:hover {
                        // text-decoration: underline;
                        &:after {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .dropdown-menu[data-bs-popper] {
            left: unset !important;
        }
    }

    .dropdown-languages-switcher {
        #languageSwitcherMenu {
            height: fit-content !important;
            @include media-breakpoint-down(xl) {
                transform: translateX(-50%);
            }
        }
        @include media-breakpoint-down(xl) {
            padding: 24px 0px;
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            @include media-breakpoint-up(xl) {
                left: unset;
                top: 40px;
                right: -14px;
                width: 140px;
                min-width: unset;
            }

            &:before {
                content: "";
                background-color: $color-school-light;
                width: 20px;
                height: 20px;
                transform: rotate(-45deg);
                position: absolute;
                right: 16.716px;
                top: -8px;
                @include media-breakpoint-down(xl) {
                    top: 88%;
                    right: unset;
                    left: calc(53%);
                }
            }

            li {
                a {
                    white-space: nowrap;
                    font-weight: 400;
                    white-space: nowrap;

                    &:hover {
                        font-weight: 700;
                    }
                }
            }
        }

        .dropdown-languages-switcher-icon {
            &:before {
                @include media-breakpoint-down(xl) {
                    content: "";
                    -webkit-mask: url("../../../public/images/languages-switcher-color-scholl.svg")
                        no-repeat 50% 50%;
                    mask: url("../../../public/images/languages-switcher-color-scholl.svg")
                        no-repeat 50% 50%;
                    -webkit-mask-size: cover;
                    mask-size: cover;
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    background-color: var(--isp-color-school);
                    display: inline-block;
                }
            }
        }

        ul {
            @include media-breakpoint-down(xl) {
                gap: 40px;
            }
        }
    }

    .dropdown-menu-search {
        padding-top: 32px;
    }
    .dropdown-menu.show {
        hr {
            opacity: 1;
            position: absolute;
            width: 100%;
            top: -4px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            height: 6px;
            margin: 0;
            border: unset;
        }
    }
    .search-input-field {
        border: solid 1px #c8cec9 !important;
        border-radius: 4px;
    }

    .search-icon-wrapper {
        min-height: 100% !important;
    }

    .search-results-wrapper {
        left: 0px;
        right: 0px;
        top: 80px;
        z-index: 100;
        position: absolute;
        background-color: $white;
        @include media-breakpoint-down(xl) {
            height: calc(100vh - 233px);
            height: calc((var(--vh, 1vh) * 100) - 233px);
            padding-left: 16px;
            padding-right: 16px;
            overflow-y: auto;
        }

        .search-single-result {
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            border-radius: 4px;
            &:hover {
                background-color: $color-school-light;
            }
        }
    }

    .search-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        color: $color-school;
    }

    .search-par {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        color: black;
    }
    .popular-searches {
        width: 80%;
        margin-bottom: 22px;
    }
    .search-section-desktop {
        .form-control {
            border-radius: 0px !important;
        }

        .search-field-desktop {
            width: 80%;
            .search-field-input-desktop {
                border: none;
                border-bottom: solid 2px $color-school;

                &:focus,
                &:focus-visible {
                    border: none;
                    border-bottom: solid $color-school 2px !important;
                }

                &:hover {
                    background-color: transparent;
                }
                &::placeholder {
                    color: $accent;
                }

                &::-ms-input-placeholder {
                    /* Edge */
                    color: $accent;
                }
            }

            .search-icon-container-desktop {
                border-bottom: solid 2px $color-school;
                border-top: none;
                border-left: none;
                border-right: none;
                background-color: transparent;
            }

            [type="search"]::-webkit-search-cancel-button {
                appearance: none;
            }

            [type="search"]::-webkit-search-decoration {
                appearance: none;
            }
        }

        .search-results-wrapper-desktop {
            width: 100%;
            top: 55px;
            z-index: 100;
            position: absolute;
            background-color: $white;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            padding-bottom: 48px;
            padding-top: 32px;
            max-height: 400px;
            overflow-y: auto;
            .search-single-result-desktop {
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                border-radius: 4px;
                width: 80%;

                &:hover {
                    background-color: $color-school-light;
                }
            }
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
}
.school-children-select-container {
    padding: 20px;
    position: absolute;
    z-index: 99;
    width: 100%;
    .school-children-select {
        width: 424px;
        color: #626f86 !important;
        height: 46px !important;
        border: 1px solid #dee2e6;
    }
    span {
        line-height: 46px !important;
        color: #626f86 !important;
        height: 46px !important;
    }
    @include media-breakpoint-down(xl) {
        width: 100% !important;
    }
}
.school-children-select {
    width: 424px;
    color: #626f86 !important;
    height: 46px !important;
    span {
        line-height: 46px !important;
        height: 46px !important;
    }
    @include media-breakpoint-down(xl) {
        width: 100% !important;
    }
}
