.widget-school-map{
    .description-tabs{
        font-size: 20px;
        p{ margin-bottom: 0;}
       
    }
    .school-map-kpis{
        h4{
            color: $color-school;
        }
        @include media-breakpoint-down(lg) {
            flex-wrap: nowrap;
        }
        .kpi{
            font-size: 20px;
            border-bottom: 1px solid $accent;
            h3{
                color: $color-school;
            }
            @include media-breakpoint-down(lg) {
                border-bottom: unset;
                border-left: 1px solid $accent;
                padding: 0 16px !important;
            }
            p{
                margin-bottom: 0;
                @include media-breakpoint-down(lg) {
                    font-size: 15px;    
                }
            }
        }
    }
    .tabs-title-tag{
        color: $color-school;
        @include media-breakpoint-down(xl) {
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 42px;  
        }
    }
}
.tabs-container{
    width: 100%;
    overflow-x: auto;

        .button-tabs{
            border-radius: 8px;
            &:first-child{
                  @include media-breakpoint-down(lg) {
                margin-left: 8px !important; 
                  }
            }
            &:last-child{
                @include media-breakpoint-down(lg) {
              margin-right: 8px !important; 
                }
          }
            &.is-active{
                background-color: $color-school;
                color: #fff;
            }

        }
    }

    .schools-column{
            max-height: 500px;
            overflow-y: auto;
            scrollbar-width: auto;
            -ms-overflow-style: auto; 
        @include media-breakpoint-down(lg) {
            padding: 0 16px;
        }
        .country{
            border-left: 1px solid $accent;
            ul{
                padding-left: 0;
            }
            .h4{
                color: $color-school;
            }
        }
        &::-webkit-scrollbar {
            display: block !important;
            width: 10px; 
          }
          
         &::-webkit-scrollbar-track {
            background: $primary; 
            border-radius: 10px;
          }
    
          &::-webkit-scrollbar-thumb {
            background: $color-school;
            border-radius: 10px;
          }
          
        &::-webkit-scrollbar-thumb:hover {
            background: $color-school;
          }
    }
    .image-map-container{
        margin-top: 56px;
        @include media-breakpoint-down(lg) {
            padding: 0 8px;
            margin-top: 40px;
            width: 100%;
        }
        .image-school-map{
            object-fit: contain;
            width: 100%;
            @include media-breakpoint-down(lg) {
            aspect-ratio: 3/2;
            padding: 0 16px;
            }
        }
    }