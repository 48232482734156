.listing-events-preview-widget {
    .events-preview-intro {
        #isp-scrollspy {
            overflow-x: auto;
            width: 100%;
        }
        margin-bottom: 48px;
        .events-preview-title {
            .h2 {
                color: $color-school;
                text-align: center;
            }
        }
        .events-preview-description {
            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                margin-bottom: 0;
            }
        }
    }
    .row-event {
        padding-top: 24px;
        padding-bottom: 24px;
        transition: 0.3s;
        &:hover {
            transform: scale(0.98);
        }
        &:nth-child(even) {
            background-color: $light-secondary;
        }
        .calendar-cell {
            position: relative;
            .calendar-info {
                position: absolute;
                top: 30px;
                width: 100%;
                @include media-breakpoint-down(lg) {
                    top: calc(50% - 17px);
                    &.calendar-flip {
                        top: calc(50% - 15px);
                    }
                }
                .calendar-day {
                    font-size: 43.297px;
                    font-weight: 700;
                    line-height: 54.122px;
                    margin-bottom: 0;
                    @include media-breakpoint-down(lg) {
                        font-size: 34.613px;
                        font-weight: 700;
                        line-height: 35px;
                    }
                }
                .calendar-month {
                    font-size: 14.432px;
                    font-weight: 700;
                    line-height: 14.432px;
                    letter-spacing: 0.289px;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    @include media-breakpoint-down(lg) {
                        font-size: 11.538px;
                        font-weight: 700;
                        line-height: 11.538px;
                        letter-spacing: 0.231px;
                    }
                }
                .event-date-mobile {
                    p {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: 0.28px;
                        margin-bottom: 2px;
                    }
                    .calendar-day {
                        margin-left: 5px;
                    }
                }
            }
            .event-time {
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0.2px;
                @include media-breakpoint-down(lg) {
                    text-transform: lowercase;
                }
            }
            svg {
                @include media-breakpoint-down(lg) {
                    width: 66px;
                    height: auto;
                }
            }
        }
        .preview-cell {
            padding-left: 32px;
            @include media-breakpoint-down(lg) {
                padding-left: 24px;
            }
        }
        .info-event-cell {
            .event-title {
                margin-bottom: 0;
            }
            .event-description {
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                max-height: 60px;
                @include media-breakpoint-down(lg) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 19px;
                    max-height: 40px;
                    margin-bottom: 16px;
                }
                strong {
                    font-weight: 400 !important;
                }
                em {
                    font-style: normal !important;
                }
                span {
                    text-decoration: none !important;
                }
            }
        }
        .date-event-cell {
            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                &::before {
                    content: "";
                    -webkit-mask: url("../../../public/images/calendar.svg")
                        no-repeat 50% 50%;
                    mask: url("../../../public/images/calendar.svg") no-repeat
                        50% 50%;
                    -webkit-mask-size: cover;
                    mask-size: contain;
                    width: 24px;
                    height: 24px;
                    background-color: var(--isp-color-school);
                    display: flex;
                    align-items: center;
                    margin-right: 24px;
                    padding-top: 4px;
                }
            }
        }
        .time-event-cell {
            p {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 0;
                white-space: nowrap;
                display: flex;
                align-items: center;
                &::before {
                    content: "";
                    -webkit-mask: url("../../../public/images/time.svg")
                        no-repeat 50% 50%;
                    mask: url("../../../public/images/time.svg") no-repeat 50%
                        50%;
                    -webkit-mask-size: cover;
                    mask-size: contain;
                    width: 24px;
                    height: 24px;
                    background-color: var(--isp-color-school);
                    display: flex;
                    align-items: center;
                    margin-right: 24px;
                    padding-top: 4px;
                }
            }
            span {
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
    .cta-container {
        margin-top: 48px;
    }
}
