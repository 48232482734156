.footer-school-pre-slogan {
    font-size: 0.875rem;
    @include media-breakpoint-down(xl) {
        font-size: 0.938rem;
    }
}

.footer-school-slogan {
    font-size: 1.25rem;
    @include media-breakpoint-down(xl) {
        font-size: 1.125rem;
    }
}

footer {
    .footer-wrapper-logo {
        height: 118px;
        img.school-logo {
            object-fit: contain;
            @include media-breakpoint-up(xl) {
                max-width: 181px;
                max-height: 118px;
            }
        }
        //school-logo img{
        //    object-fit: contain;
        //    @include media-breakpoint-up(xl) {
        //        max-width: 181px;
        //        max-height: 118px;
        //    }
        //}
    }
    
}


.footer-columns {
    padding-top: 62px;

    .footer-column-title {
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 32px !important;
        @include media-breakpoint-down(xl) {
            font-size: 1.125rem;
        }
    }

    .footer-arrow-right {
        margin-left: 10px;
        width: 7px;
        height: 11px;
        transform: translateY(5%);
    }

    .footer-user-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .footer-contact-us {
        font-weight: bold !important;
    }
}

.footer-logos-wrapper {
    padding-bottom: 32px;

    a.footer-social-link {
        margin-top: 91px;
        border: solid $textColor 1px;
        border-radius: 50%;
        width: 60px;
        height: 60px;

        &:hover {
            cursor: pointer;
        }

        &:focus-visible {
            border-radius: 50%;
            width: 60px;
            height: 60px;
            outline: $primary auto 1px !important;
            outline-offset: 0;
        }

        .footer-social-logo {
            height: 19px;
        }
    }

    .isp-logo-footer {
        margin-top: 91px;
    }
}

.footer-mobile-wrapper {
    overflow-x: hidden;

    .footer-mobile-logos-divider {
        height: 44px;
        width: 1px;
        margin-left: 14px;
        border-left: solid $textColor 1px;
    }

 

    .footer-logos-wrapper-mobile {
        margin: 20px 0px 17px;
        width: 262px;
        height: 68px;

        //.footer-school-logo-mobile img{
        //    max-width: 108px;
        //    max-height: 66px;
        //    object-fit: contain;
        //}
        //
        //.isp-logo-footer-mobile img{
        //    width: 114px;
        //    height: 31px;
        //    object-fit: contain;
        //}

        .footer-school-logo-mobile {
            max-width: 108px;
            max-height: 66px;
        }

        .isp-logo-footer-mobile {
            width: 114px;
            height: 31px;
        }
    }

    .footer-col-title-mobile {
        font-size: 1.15rem;
    }

    .footer-cta-collapse {
        margin-top: 14px;
        margin-bottom: 96px;

        .btn.collapse-content {
            &:focus-visible {
                border-radius: 4px;
                outline: 2px solid $white !important;
            }
        }
        .footer-arrow-down {
            transform: rotate(270deg);
            width: 12px;
            height: 20px;
        }
    }

    .footer-social-link  {
        margin-top: 32px;
        border: solid white 1px;
        border-radius: 50%;
        width: 45px;
        height: 45px;

        &:hover {
            cursor: pointer;
        }

        &:focus-visible {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            outline: $primary auto 1px !important;
            outline-offset: 0;
        }

        .footer-social-logo-mobile {
            height: 14px;
        }
    }

    .footer-cta-mobile {
        width: 187px;
        height: 46px;
    }
}

.contact-us-cta {
    &.btn-only-arrow {
        &:after {
            background-color: $white;
        }
        &.text-white:focus-visible {
            outline: 2px solid $white !important;
        }
    }
}

.whatsapp-cta:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.contact-info {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.collapse-content[aria-expanded="false"] .footer-arrow-down {
    transform: rotate(90deg);
}

.footer-link {
    font-size: 0.875rem;
    font-weight: 400;
    &:hover {
        font-weight: 700;
    }
    &.text-white:focus-visible {
        outline: 2px solid $white !important;
    }   
}

.footer-link-portal {
    font-size: 16px !important;
    font-weight: 400;
}

.footer-phone-link {
    margin-bottom: 16px !important;
}