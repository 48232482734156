.multimedia-widget {
    .multimedia-filters {
        padding-left: 0;
        padding-right: 0;
    }
    @include media-breakpoint-down(md) {
        padding-left: 0px;
        padding-right: 0px;
    }
    .nav-tabs {
        border-bottom: 0;
        margin-bottom: 48px;
        gap: 16px;
        @include media-breakpoint-down(md) {
            flex-wrap: nowrap;
            overflow: scroll;
        }
    }
    .nav-item button {
        border: 1px solid $color-school !important;
        border-radius: 8px;
        // margin: 0 8px;
        color: #000;
        font-size: 14px;
        padding: 8px 16px;
        white-space: nowrap;
        &.active {
            background-color: $color-school;
            color: $white;
        }
    }
    .nav-item {
        @include media-breakpoint-down(md) {
            &:first-child {
                margin-left: 16px;
            }
            &:last-child {
                margin-right: 16px;
            }
        }
    }
    .multimedia-masonry {
        @include media-breakpoint-down(md) {
            margin-left: 8px;
            margin-right: 8px;
        }
        &.fade:not(.show) {
            height: 0 !important;
            overflow: hidden;
        }
        .text-image-card {
            &.only-image {
                picture {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
            &.card-icon {
                position: relative;
                picture {
                    width: 70%;
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .icons-images-title-container {
                    padding: 24px;
                    @include media-breakpoint-down(md) {
                        padding: 12px;
                    }
                    &.image-card-title {
                        padding: 0;
                        .title-card-container {
                            padding: 24px;
                            @include media-breakpoint-down(md) {
                                padding: 12px;
                            }
                        }
                    }
                    .title-card-container {
                        p {
                            color: $color-school;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @include media-breakpoint-down(md) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                    .title-card-icon-container {
                        .small-circle-container {
                            margin-right: -10px;
                            width: 20%;
                            .small-circle {
                                background-color: $accent;
                                border-radius: 50%;
                                width: 100%;
                                aspect-ratio: 1/1;
                            }
                        }
                        .big-circle-container {
                            width: 60%;
                            .big-circle {
                                background-color: $color-school;
                                aspect-ratio: 1/1;
                                height: auto;
                                border-radius: 50%;
                                margin-bottom: 24px;
                                picture {
                                    width: 55%;
                                }
                                img {
                                    height: auto;
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
                .icon-images-container {
                    position: relative;
                    height: auto;
                    aspect-ratio: 1/1;
                    picture {
                        height: auto;
                    }
                    .small-circle-container {
                        display: flex;
                        align-items: end;
                        height: 100%;
                        width: 25%;
                        position: relative;
                        .small-circle {
                            position: absolute;
                            bottom: -2%;
                            left: -15%;
                            width: 110%;
                            height: min-content;
                            aspect-ratio: 1/1;
                            border-radius: 50%;
                            background-color: $accent;
                        }
                    }
                    .big-circle-container {
                        display: flex;
                        align-items: start;
                        height: 100%;
                        width: 75%;
                        position: relative;
                        .big-circle {
                            right: 0;
                            height: min-content;
                            position: absolute;
                            top: 0;
                            background-color: $color-school;
                            width: 125%;
                            right: -15%;
                            aspect-ratio: 1/1;
                            border-radius: 50%;
                            img {
                                width: 80%;
                                object-fit: contain;
                            }
                        }
                    }
                }
                .image-container {
                    width: 100%;
                    height: 100%;
                    flex-grow: 1;
                    overflow: hidden;
                    mask-size: cover;
                    mask-repeat: no-repeat;
                    mask-position: top center;
                    position: relative;
                    picture {
                        width: 100%;
                        height: 100%;
                    }
                    &.image-small {
                        -webkit-mask-image: url("../../../public/images/small.png");
                        mask-image: url("../../../public/images/small.png");
                    }
                    &.image-medium {
                        -webkit-mask-image: url("../../../public/images/medium.png");
                        mask-image: url("../../../public/images/medium.png");
                    }
                    &.image-large {
                        -webkit-mask-image: url("../../../public/images/large.png");
                        mask-image: url("../../../public/images/large.png");
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .card-text {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .media-quote {
            .card-quote-text {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .col-lg-4 {
            .card-ratio-small {
                .text-image-card {
                    .card-text {
                        -webkit-line-clamp: 6;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 4;
                        }
                        @include media-breakpoint-down(md) {
                            -webkit-line-clamp: 3;
                        }
                    }
                }
                .media-quote {
                    .card-quote-text {
                        -webkit-line-clamp: 5;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 3;
                        }
                        @include media-breakpoint-down(md) {
                            -webkit-line-clamp: 2;
                        }
                    }
                }
            }
            .card-ratio-medium {
                .text-image-card {
                    .card-text {
                        -webkit-line-clamp: 8;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 6;
                        }
                        @include media-breakpoint-down(md) {
                            -webkit-line-clamp: 4;
                        }
                    }
                }
                .media-quote {
                    .card-quote-text {
                        -webkit-line-clamp: 7;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 5;
                        }
                        @include media-breakpoint-down(md) {
                            -webkit-line-clamp: 4;
                        }
                    }
                }
            }
            .card-ratio-large {
                .text-image-card {
                    .card-text {
                        -webkit-line-clamp: 10;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 8;
                        }
                        @include media-breakpoint-down(md) {
                            -webkit-line-clamp: 6;
                        }
                    }
                }
                .media-quote {
                    .card-quote-text {
                        -webkit-line-clamp: 9;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 7;
                        }
                        @include media-breakpoint-down(md) {
                            -webkit-line-clamp: 5;
                        }
                    }
                }
            }
        }
        .col-lg-3 {
            .card-ratio-small {
                .text-image-card {
                    .card-text {
                        -webkit-line-clamp: 4;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 3;
                        }
                    }
                }
                .media-quote {
                    .card-quote-text {
                        -webkit-line-clamp: 4;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 3;
                        }
                    }
                }
            }
            .card-ratio-medium {
                .text-image-card {
                    .card-text {
                        -webkit-line-clamp: 6;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 5;
                        }
                    }
                }
                .media-quote {
                    .card-quote-text {
                        -webkit-line-clamp: 5;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 4;
                        }
                    }
                }
            }
            .card-ratio-large {
                .text-image-card {
                    .card-text {
                        -webkit-line-clamp: 7;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 6;
                        }
                    }
                }
                .media-quote {
                    .card-quote-text {
                        -webkit-line-clamp: 6;
                        @include media-breakpoint-down(xxl) {
                            -webkit-line-clamp: 5;
                        }
                    }
                }
            }
        }
        .card {
            border-radius: 16px;
            border: unset;
            overflow-y: hidden;
            height: auto;
            position: relative;
            transition: 0.3s;
            &.color-primary {
                background-color: $color-school-light;
            }
            &.color-secondary {
                background-color: $accent-light;
            }
            &:hover {
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
                .card-body {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
            .button-mobile-container,
            .button-link-mobile {
                bottom: 8px;
                right: 8px;
                background-color: $white;
                border-radius: 100%;
                z-index: 1;
                width: 44px;
                height: 44px;
                @include media-breakpoint-down(md) {
                    width: 32px;
                    height: 32px;
                }
                svg {
                    width: 20px;
                    path {
                        stroke: $color-school;
                    }
                }
            }
            &:hover {
                .card-overlay {
                    z-index: 1;
                    opacity: 0.6;
                    cursor: pointer;
                    @include media-breakpoint-down(md) {
                        opacity: 1;
                    }
                }
                .button-card {
                    opacity: 1;
                }
            }
            .card-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $color-school;
                opacity: 0;
                border-radius: 16px;
                z-index: 0;
                transition: 0.3s;
                @include media-breakpoint-down(md) {
                    opacity: 1;
                    background-color: transparent;
                    z-index: 1;
                }
            }
            .button-card {
                position: absolute;
                bottom: 0;
                z-index: 1;
                opacity: 0;
                transition: 0.3s;
                @include media-breakpoint-down(md) {
                    background-color: $white;
                    width: 32px !important;
                    height: 32px;
                    border-radius: 50%;
                    opacity: 1;
                    bottom: 8px;
                    right: 8px;
                }
            }
            &.card-ratio-small {
                aspect-ratio: 1/1;
                height: auto;
            }
            &.card-ratio-medium {
                aspect-ratio: 4/5;
                height: auto;
            }
            &.card-ratio-large {
                aspect-ratio: 2/3;
                height: auto;
            }
            picture {
                height: 100%;
            }
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 16px;
                height: 100%;
                transition: 0.3s;
            }
            .card-body {
                padding: 0;
                overflow-y: hidden;
                padding: 24px;
                position: relative;
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    padding: 12px;
                }
                &.media-quote {
                    background-color: $color-school-light;
                }
                .video-play {
                    position: absolute;
                    width: 44px;
                    height: 44px;
                    background-color: $white;
                    border-radius: 50%;
                    left: calc(50% - 22px);
                    svg {
                        path {
                            fill: $color-school;
                            stroke: $color-school;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        width: 32px;
                        height: 32px;
                        svg {
                            width: 16px;
                        }
                    }
                }
                &.text-image-card {
                    &.card-media-image {
                        padding: 0;
                    }
                }
                p {
                    color: $white;
                }
                .card-quote-text,
                .card-text {
                    font-size: 48px;
                    font-weight: 700;
                    line-height: 60px;
                    color: $color-school;
                    margin-bottom: 0;
                    @include media-breakpoint-down(xl) {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
                .card-text {
                    color: $color-school;
                }
                &.media-opportunity {
                    padding: 0;
                }
                .quote-image {
                    width: 78px;
                    svg {
                        @include media-breakpoint-down(md) {
                            width: 40px;
                        }
                        path {
                            fill: $accent;
                        }
                    }
                }
            }
        }
        .caption-card {
            margin-top: 16px;
            @include media-breakpoint-down(md) {
                margin-top: 8px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0;
            }
        }
    }
}
.modal-quote-body-multimedia p {
    font-size: 20px;
    @include media-breakpoint-down(md) {
        font-size: 16px;
    }
}
