.not-found-message{
    @include media-breakpoint-down(md) {
        padding-left: 16px;
        padding-right: 16px;
    }
    .not-found-title{
        font-size: 200px;
        font-weight: 700;
        line-height: 200px;
        color: $color-school;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            font-size: 100px;
            line-height: 100px; 
        }
    }
    .not-found-subtitle{
        color: $color-school; 
        margin-top: 64px;
        margin-bottom: 8px;
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
    }
    .not-found-description{
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 48px;
        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 30px;
        }
    }
}