/* Customize Bootstrap Variables */
@import "fonts";
@import "variables";

/* Vendors section */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons";
@import "aos/src/sass/aos.scss";
@import "swiper/css";
// @import 'select2/src/scss/core.scss';

/* Main style section */
@import "style";

/* Widget section, add here all widget stylesheets */

@import "./../../templates/areas/accordion/accordion";
@import "./../../templates/areas/chessboard/chessboard";
@import "./../../templates/areas/editorial/editorial";
@import "./../../templates/areas/footer/footer";
@import "./../../templates/areas/gallery/gallery";
@import "./../../templates/areas/timeline-icon/timeline_icon";
@import "./../../templates/areas/dual-curriculum/dual_curriculum";
@import "./../../templates/areas/dual-curriculum-cards/dual_curriculum_cards";
@import "./../../templates/areas/header/header";
@import "./../../templates/areas/hero-image/hero_image";
@import "./../../templates/areas/hero-slim/hero_slim";
@import "./../../templates/areas/hero-video/hero_video";
@import "./../../templates/areas/highlights/highlights";
@import "./../../templates/areas/image-card-scroll/image_card_scroll";
@import "./../../templates/areas/image/image";
@import "./../../templates/areas/intro-banner/intro_banner.scss";
@import "./../../templates/areas/intro/intro";
@import "./../../templates/areas/intro-horizontal/intro-horizontal";
@import "./../../templates/areas/kpi/kpi";
@import "./../../templates/areas/kpi-bubbles/kpi-bubbles";
@import "./../../templates/areas/opportunities/opportunities";
@import "./../../templates/areas/outline-card-fixed/outline_card_fixed";
@import "./../../templates/areas/outline-card-scroll-horizontal/outline_card_scroll-horizontal";
@import "./../../templates/areas/overview-countdown/overview_countdown";
@import "./../../templates/areas/partnership/partnership";
@import "./../../templates/areas/quote/quote";
@import "./../../templates/areas/short-contact/short_contact";
@import "./../../templates/areas/sub-header/sub_header";
@import "./../../templates/areas/video/video";
@import "./../../templates/areas/overview-tabs/overview_tabs.scss";
@import "./../../templates/areas/overview-editorial/overview_editorial.scss";
@import "./../../templates/areas/timeline-image/timeline_image.scss";
@import "./../../templates/areas/learning-pillars/learning_pillars.scss";
@import "./../../templates/areas/latest-news/latest_news.scss";
@import "./../../templates/areas/image-card-fixed/image_card_fixed.scss";
@import "./../../templates/areas/spacer/spacer.scss";
@import "./../../templates/areas/map/map.scss";
@import "./../../templates/areas/people-card/people_card.scss";
@import "./../../templates/areas/community-voices/community_voices.scss";
@import "./../../templates/areas/form/form.scss";
@import "./../../templates/areas/editorial-tab/editorial_tab.scss";
@import "./../../templates/areas/listing-events-preview/listing_events_preview.scss";
@import "./../../templates/layouts/event/show.scss";
@import "./../../templates/areas/tab/tab.scss";
@import "./../../templates/areas/form-and-map/form_and_map.scss";
@import "./../../templates/areas/form-and-text/form_and_text.scss";
@import "./../../templates/areas/table/table.scss";
@import "./../../templates/areas/listing-news/listing_news.scss";
@import "./../../templates/areas/listing-events/listing_events.scss";
@import "./../../templates/areas/breadcrumbs/breadcrumbs.scss";
@import "./../../templates/layouts/news/show.scss";
@import "./../../templates/includes/popup/popup.scss";
@import "./../../templates/layouts/error/error.scss";
@import ".././../templates/areas/opportunities/opportunities.scss";
@import "./../../templates/areas/schools-card/schools-card";
@import ".././../templates/areas/multimedia/multimedia.scss";
@import ".././../templates/areas/opportunities/opportunities.scss";
@import ".././../templates/includes/landing/header/header-landing";
@import ".././../templates/includes/landing/footer/footer-landing";
@import ".././../templates/areas/schools-map/schools_map.scss";
@import ".././../templates/areas/wheel/wheel.scss";