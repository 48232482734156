.outline-card-fixed-widget {
    .outline-wrapper-card {
        &:last-child{
            margin-right: 0;
            @include media-breakpoint-down(md) {
                margin-right: 8px;
            }
        }
        &:last-left {
            margin-left: 0;
            @include media-breakpoint-down(md) {
                margin-left: 8px;
            }
        }
        @include media-breakpoint-down(md) {
            padding-bottom: 16px;
            &:last-child{
                padding-bottom: 0;
            }
        }
    }
    @include media-breakpoint-down(md) {
       padding-right: 16px;
       padding-left: 16px;
    }
    .outline-card-fixed{
        border: 1px solid $primary;
        border-radius: 8px;
        .card-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.48px;
            color: $color-school;
            margin-bottom: 8px;
            // height: 28px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            @include media-breakpoint-down(md) {
                font-size: 22px;
                line-height: 30px;
            }
        }
        .card-text img{
            max-width: 100%;
        }
        .card-text p{
            font-size: 14px;
            line-height: 18px; 
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow : hidden;
            // height: 54px;
            @include media-breakpoint-down(md) {
                font-size: 15px;
                line-height: 19px;
                -webkit-line-clamp: 4;
            }
        }
    }
}