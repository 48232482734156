.form-map-title {
    color: $color-school;
}

.text-side-wrapper {
    padding: 80px 60px;
    @include media-breakpoint-down(lg) {
        padding: 48px 16px;
    }
    p {
        font-size: 20px;
        line-height: 32px;
    }
}

.form-side-wrapper-formtext {
    background-color: $accent-light;
    padding: 80px 60px;

    .actions {
        input {
            margin-top: 0px !important;
        }
    }

    .hbspt-form form .hs-form-field .input textarea {
        height: 104px !important;
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .form-map-paragraph {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        a {
            color: $color-school;
        }
    }
}
