.category-news-detail{
    font-size: 20px;
    line-height: 32px;
    color: $color-school;
    margin-bottom: 8px;
    @include media-breakpoint-down(md) {
        font-size: 18px; 
        margin-top: 16px;
    }
}
.date-news-detail{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 40px;
    margin-top: 8px;
}