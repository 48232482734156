.popup-isp {
    border: 2px solid $primary;
    border-radius: 52px;
    padding: 4px 48px 4px 4px;
    background-color: $white;
    bottom: 40px;
    right: calc(50% - 25%);
    z-index: 999;
    position: fixed;
    display: flex;
    @include media-breakpoint-down(xl) {
        display: none !important;
    }
    .popup-image {
        margin-right: 16px;
        img {
            height: 100px;
            width: 100px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .popup-title {
        justify-content: space-between;
        width: 100%;

        p {
            font-size: 18px;
            font-family: $font-family-heading;
            font-weight: 700;
            line-height: 28px;
            color: $color-school;
            margin-bottom: 0;
            display: inline-block;
            padding-right: 16px;
        }
        .popup-line {
            background-color: $accent;
            width: 4px;
            border-radius: 8px;
            height: 48px;
        }
    }
    .popup-description {
        padding-left: 16px;
        p {
            font-size: 16px;
            font-weight: 425;
            line-height: 16px;
            letter-spacing: 0.32px;
            margin-bottom: 0;
        }
    }
    .btn-popup {
        padding-left: 24px;
    }
    .botton-close-popup {
        width: 40px;
        height: 40px;
        background-color: $color-school-light;
        border-radius: 50%;
        border: none;
        position: absolute;
        right: -20px;
        top: calc(50% - 20px);
        svg {
            width: 32px;
            height: 32px;
            path {
                stroke: $color-school;
            }
        }
    }
}
