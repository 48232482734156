.map-title {
    color: $color-school;
}

.map-paragraph {
    span:after {
        content: "";
        -webkit-mask: url("../../../public/images/location-black.svg") no-repeat 50% 50%;
        mask: url("../../../public/images/location-black.svg") no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        width: 17px;
        height: 22px;
        background-color: var(--isp-color-school);
        display: inline-block;
        margin-right: 8px;

        @include media-breakpoint-down(md) {
            width: 13px;
            height: 17px;
        }
    }
}

.widget-map-map {
    height: 680px;
    margin-bottom: 24px;
    margin-top: 24px;

    @include media-breakpoint-down(md) {
        margin-bottom: 32px;
        margin-top: 32px;
    }
    .custom-tooltip{
        padding: 0;
        a{
            color: $color-school;
            width: 140px;
            text-align: start;
        }
    }
}

.map-buttons-wrapper {
    @include media-breakpoint-down(md) {
        flex-direction: column !important;
        align-items: center !important;
    }
}

