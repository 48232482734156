.image-card-fixed-widget {
    background-color: $color-school-light;
    overflow: hidden;

    &.container-fluid {
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }

        .container {
            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
                max-width: unset;
            }
        }
    }

    .image-card-fixed-title-tag {
        color: $color-school;
        margin-bottom: 32px;

        @include media-breakpoint-down(md) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .card-fixed-container {
        @include media-breakpoint-down(md) {
            overflow-x: auto;
            width: 100%;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
                /* Safari and Chrome */
            }
        }
    }

    .card-fixed {
        position: relative;
        min-width: 267px;

        @include media-breakpoint-down(md) {
            width: 267px;
        }

        .card-fixed-image {
            width: 100%;
            overflow: hidden;
            border-radius: 8px;
            img {
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
                height: 100%;
                transition: .4s;

                @include media-breakpoint-up(md) {
                    height: auto;
                }
            }
        }

        .card-fixed-link {
            width: calc(100% - 38px);
            background-color: $white;
            padding: 16px;
            border-radius: 8px;
            margin-top: -36px;
            z-index: 1;
            position: relative;

            .card-fixed-link-title {
                color: $color-school;
            }


            button {
                border: unset;
                padding: 0;
                border-radius: 50%;
                background-color: $white;
                transform: rotate(180deg);

                &.button-gallery-flip {
                    transform: rotate(0deg);
                }
            }

            span {
                font-size: 24px;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0.48px;
            }

            svg {
                stroke: $color-school;

                path {
                    fill: $color-school;

                }
            }
        }

        &:first-child {
            @include media-breakpoint-down(md) {
                padding-left: 16px;
            }
        }

        &:last-child {
            @include media-breakpoint-down(md) {
                padding-right: 16px;
            }
        }
        &:hover{
            .card-fixed-image {
                box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
                img{
                    transform: scale(1.1);
                }
            }
        }

    }
}