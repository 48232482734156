.footer-landing {
    .footer-wrapper-logo-landing {
        img.school-logo {
            @include media-breakpoint-up(xl) {
                max-width: 293px;
                max-height: 118px;
            }
        }
    }
    .footer-logos-wrapper-mobile {
        .footer-school-logo-mobile-landing{
            max-width: 293px;
            max-height: 68px;
        }
    }
}
