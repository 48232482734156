.short-contacts-widget {

    .widget-contacts-contact-card {

        margin-top: 8px;

        @include media-breakpoint-down(xl) {
            margin-top: 32px;
        }

        .short-contacts-contact-title {
            color: $color-school;
            letter-spacing: 0.32px;
        }

        .short-contacts-contact-info {
            font-size: 1rem;
            font-weight: 425;
            line-height: 16px;
            letter-spacing: 0.32px;
            white-space: nowrap;

            .short-contact-prefix {
                color: $color-school;
                font-weight: 700;

                svg {
                    fill: $color-school;
                }
            }

            .widget-contacts-tel-link {
                color: $black;
            }
        }

    }


}