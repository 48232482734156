.editorial-widget {
    h1, h2, h3, h4, h5, h6 {
        color: $color-school;
    }
    .editorial-content > :last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .editorial-content img{
        max-width: 100%;
    }
    a {
        text-decoration: underline;
        color: $color-school;
      }
    .editorial-title{
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 28px;
        margin-bottom: 32px;
        letter-spacing: 0.48px;
        @include media-breakpoint-down(md) {
            font-weight: bold;
            font-size: 1.37rem;
            line-height: 30px;
        }
    }
    p{
        //padding-bottom: 16px;
        font-size: 18px;
        line-height: 28px;
    }
    ul, ol {
        //padding-bottom: 16px;
        padding-left: 0;
            li{
                font-size: 18px;
                line-height: 28px;
                //padding-bottom: 16px;
                //margin: 0 24px;
                padding: 0 4px;
                 &::marker {
                font-size: 12px;
                font-weight: bold;
            }
        }
    }
    a {
        text-decoration: underline;
        color: var(--isp-color-school);
    }
    
}