.highlights-widget
{ 
    position: relative;
    overflow-x: hidden;
    .circle-background{
        position: absolute;
        height: 100%;
        width: auto;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $accent-light;
        top:0;
        z-index: 0;
        &.flex-row-reverse{
            left: -16%;
            @include media-breakpoint-down(sm) {
                left: unset;
                right: 16px;
            }
        }
        &.flex-row{
            right: -16%;
            @include media-breakpoint-down(sm) {
                left: 16px;
                right: unset;
            }
        }
    }
    .highlights-row{
        &.flex-row-reverse{
            .col-text{
                @include media-breakpoint-down(md) {
                    padding-left: 16px;
                    padding-right: 16px;
                 }
            }
            .col-images{
                padding-left: 72px;
            }
        }
        &.flex-row{
            .col-text{
                @include media-breakpoint-down(md) {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
              .col-images{
                padding-right: 72px;
            }
        }
        .col-text{
            position: relative;
            z-index: 1;
            .cta-container{
                margin-top: 40px;
                @include media-breakpoint-down(md) {
                    margin-top: 32px;
                }
            }
            .highlights-description{
                strong{
                    display: contents;
                }
            }
            .highlights-description:last-child{
                margin-bottom: 0;
            }
            :not(.cta-container) > a {
                text-decoration: underline;
                color: $color-school;
              }
            .highlights-title{
                font-weight: bold;
                font-size: 3rem;
                line-height: 60px;
                @include media-breakpoint-down(md) {
                    font-weight: bold;
                    font-size: 2.25rem;
                    line-height: 42px;
                }
            }
            p{
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 28px;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    line-height: 30px;
                    margin-bottom: 24px;
                }
            }
            ul {
                margin: unset;
                margin-bottom: 28px;
                    li{
                        font-size: 20px;
                        line-height: 32px;
                        padding-bottom: 12px;
                        margin: 12px 0px;
                        padding: 0 4px;
                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                            line-height: 30px;
                        }
                        &::marker {
                            color: $accent;
                            font-size: 1.6rem;
                            width: 8px;
                            height: 8px;
                        }
                }
            }
            ol {
                padding: unset;
                margin: unset;
                margin-bottom: 28px;
                padding-right: 28px;
                padding-left: 28px;
                    li{
                        font-size: 20px;
                        line-height: 32px;
                        padding-bottom: 12px;
                        margin: 12px 0px;
                        padding: 0 4px;
                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                            line-height: 30px;
                        }
                         &::marker{
                           color: $accent;
                            margin-right: 12px;
                            padding-top: 4px;
                            font-weight: bold;
                            font-size: 18px;
                         }
                }
            }
        }
        .is-background-circle-1{
            padding-top: 40px;
            padding-bottom: 112px;
            @include media-breakpoint-down(md) {
                padding-bottom: 80px;
            }
        }
        .col-images.is-background-circle-1{
            padding-top: 40px;
        }
        .col-images{
            .circle-image{
                width:75%;
                picture {
                    width: 100%;
                }
                img{
                    width:100%;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    border-radius: 50%;
                    height: auto;
                    position: relative;
                    z-index: 1;
                } 
                padding-bottom: 68px;
            }
            .circle-color{
                width: 25%;
              position: relative;
              height: fit-content;
              margin-top: auto;
                    z-index: 1;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    background-color: $accent;
              
            }
        }
    }
}