.overview-editorial-widget{
    position: relative;
    padding-right:0 !important;
    padding-left: 0 !important;
    .col-event-image{
        padding: 0;
        img{
            width:100%;
            aspect-ratio: 1/1;
            height: auto;
            object-fit: cover;
            border-radius: 0 8px 8px 0;
            @include media-breakpoint-down(lg) {
                border-radius: 8px;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-bottom: 48px;
            padding: 0 16px;
        }
        &.ratio-16-9{
            img{
                aspect-ratio: 16/9;
            }
        }
        &.ratio-4-3{
            img{
                aspect-ratio:4/3;
            }
        }
    }
    .flex-lg-row-reverse{
        .col-event-image{
            padding: 0;
            img{
                width:100%;
                aspect-ratio: 1/1;
                height: auto;
                object-fit: cover;
                border-radius: 8px 0 0 8px;
                @include media-breakpoint-down(lg) {
                    border-radius: 8px;
                }
            }
            @include media-breakpoint-down(lg) {
                margin-bottom: 48px;
                padding: 0 16px;
            }
            &.ratio-16-9{
                img{
                    aspect-ratio: 16/9;
                }
            }
            &.ratio-4-3{
                img{
                    aspect-ratio:4/3;
                }
            }
        }

    }
        .container-editorial-content{
            @include media-breakpoint-down(lg) {
                position: relative;
                padding: 0 16px;
            }
            img{
                path{
                    fill: $color-school;
                }
            }
            .column-content{
                
                .editorial-title{
                    .editorial-title-tag{
                font-size: 48px;
                font-weight: 700;
                line-height: 60px; 
                color: $color-school;
                margin-bottom: 8px;
                @include media-breakpoint-down(lg) {
                font-size: 36px;
                line-height: 42px; 
                }
                    }
                }
                .editorial-description{
                    p{
                        font-size: 20px;
                        line-height: 32px; 
                        margin-bottom: 0px;
                        @include media-breakpoint-down(lg) {
                            font-size: 18px;
                            line-height: 30px;
                            margin-bottom: 0px;
                        }
                    }
                    a {
                        color: $color-school;
                    }
                }
            }
        }
        .category-tabs{
            background-color: $color-school;
            padding: 6px 10px;
            border-radius: 4px;
            width: fit-content;
            margin-bottom: 48px;
            span{
                color: $white;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px
            }
            @include media-breakpoint-down(lg) {
                margin-bottom: 32px;
              
            }

        }
}