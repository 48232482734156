.community-voices-widget{
    position: relative;
    &.container-fluid{
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .community-title-tag{
        color: $color-school;
        margin-bottom: 8px;
    }
    .community-intro-container{
        margin-bottom: 72px;
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
         }
    }
    .community-description{
        p{
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
           font-size: 18px;
            line-height: 30px;
        }
        }
    }
    .community-slider-content{
        .community-slide{
            opacity: 0 !important; 
            transition: opacity 0.5s; 
            &.swiper-slide-active {
                opacity: 1 !important; 
                z-index: 1;
                @include media-breakpoint-down(md) {
                    transform: scale(1);
                }
              }
            .community-image{
                img{
                    width: 100%;
                    aspect-ratio: 1/1;
                    height: auto;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            .community-text{
                .community-text-content{
                background-color: $white;
                padding: 32px;
                border-radius: 8px;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07); 
                margin-left: -80px;
                position: relative;
                z-index: 1;
                width: calc(100% + 80px);
                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-left: unset;
                }
                .review-title{
                    color: $color-school;
                    margin-bottom: 24px;
                }
                .review-short{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 32px;
                    margin-bottom: 24px;
                }
                .community-name{
                    font-size: 14px;
                    color: $color-school;
                    font-weight: 700;
                    line-height: 18px;
                    margin-bottom: 8px;
                }
                .community-job{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    margin-bottom: 8px;
                }
                   button {
                        cursor: pointer;
                        margin-top: 16px;
                        &:hover {
                            color: $color-school;
                        }

                   }
                        button:before{
                            content: "";
                            -webkit-mask: url("../../../public/images/video.svg") no-repeat 50% 50%;
                            mask: url("../../../public/images/video.svg") no-repeat 50% 50%;
                            -webkit-mask-size: cover;
                            mask-size: cover;
                            width: 24px;
                            height: 24px;
                            background-color: var(--isp-color-school);
                            display: inline-block;
                            margin-right: 10px;
                        }
                }
                .text-image-quote{
                    position: absolute;
                    bottom: 32px;
                    right: 32px;
                    &.svg-flip{
                        transform: scaleX(-1);
                    }
                    @include media-breakpoint-down(md) {
                        position: relative;
                        bottom: unset;
                        right:unset;
                    }
                    svg{
                    width: 60px;
                    height: auto;
                    @include media-breakpoint-down(md) {
                        width: 80px;
                    }
                    path{
                    fill: $accent;
                    }
                    }
                }
            }
        }
    }

    //Slider Thumbs
    .community-slider-thumbs{
        position: relative;
        margin-top: 80px;
        @include media-breakpoint-down(md) {
            margin-top: 0px;
        }
        .community-slide{
            opacity: .5;
            cursor: pointer;
            @include media-breakpoint-down(md) {
                transition: transform .3s;
                transform: scale(.8);
            }
            &.swiper-slide-active{
                @include media-breakpoint-down(md) {
                    transform: scale(1);
                    opacity: 1;
                }
            }
            &.swiper-slide-thumb-active{
                opacity: 1;
                @include media-breakpoint-down(md) {
                    transform: scale(1);
                }
            }
            .community-job{
                    margin-bottom: 0;
                    margin-top: -15px;
            }
            .community-image{
                img{
                    width: 100%;
                    aspect-ratio: 1/1;
                    height: auto;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
      
    }
    .swiper-button-disabled{
        opacity: .4;
        img{
            opacity: .4;
        }
    }
    .swiper-button-prev {
        cursor: pointer;
        position: absolute;
        bottom: calc(50% - 50px + 22px);
        left: 64px;
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        @media (max-width:1440px)  {
            left: 16px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        &.button-gallery-flip{
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        position: absolute;
        cursor: pointer;
        transform: rotate(180deg);
       bottom: calc(50% - 50px + 22px);
        right: 64px;
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        &.button-gallery-flip{
            transform: rotate(-360deg);
        }
        @media (max-width:1440px) {
            right: 16px;
            }
        @include media-breakpoint-down(md) {
                display: none;
            }

            .community-slider-thumbs-mobile-container{
                .swiper-slide-thumb-active{
                    transform: scale(1.1);
                }
                .swiper-slide-prev, .swiper-slide-prev{
                    transform: scale(.9);
                }
            }
       
    }
    .community-slider-thumbs-mobile-container{
        &.container-fluid{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .cards-gallery-mobile{
        padding: 0px 16px 18px 16px;
    }
}