.hero-widget {
    height: calc(100vh - var(--isp-header-height-mobile));
    height: calc((var(--vh, 1vh) * 100) - var(--isp-header-height-mobile)); 
    min-height: 400px;
    @include media-breakpoint-up(md) {
        min-height: 600px;
    }
    @include media-breakpoint-between(md, xl) { 
        height: calc(60vh - var(--isp-header-height-mobile));
        height: calc((var(--vh, 1vh) * 60) - var(--isp-header-height-mobile));
    }

    @include media-breakpoint-up(xl) {
        height: calc(100vh - 100px);
        height: calc((var(--vh, 1vh) * 100) - 100px);
    }

    .swiper-slide  {
        background: linear-gradient(333deg, $backgroundColor 91%, $primary 91%);
        @include media-breakpoint-up(md) {
            background: linear-gradient(333deg, var(--isp-background-color) 61%, var(--bs-primary) 55%);
        }

        .row-container {
            height: 100%;
            @include media-breakpoint-up(md) {
                height: 100%;
            }
        }
       
        .wrapper-image-col {
            @include media-breakpoint-up(md) {
               height: 100%;
            }
        }

        img {
            -webkit-mask-image: url("../../../public/images/mask-hero-mobile.png");
            mask-image: url("../../../public/images/mask-hero-mobile.png");
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            width: 272px;
            height: 340px;
            object-fit: cover;
            @include media-breakpoint-up(md) {
                -webkit-mask-image: url("../../../public/images/mask-hero-desktop.png");
                mask-image: url("../../../public/images/mask-hero-desktop.png");
                width: 100%;
                height: 100%;
            }   
        }

        .hero-wrapper-text {
            margin-right: 21%;
            margin-top: 10%;
            
            @media (max-width:1600px)  {
                margin-right: 7%;
            }
            @include media-breakpoint-down(xxl) {
                margin-right: 4%;
                margin-top: 20%;
            }

            @include media-breakpoint-between(lg, xxl) { 
                margin-top: 7%;
            }

            @include media-breakpoint-between(md, lg) {
                margin-top: 0%;
            }
        
            @include media-breakpoint-down(md) {
                height: 50%;
                margin-top: 100px;
            }

            .hero-title {
                @include media-breakpoint-down(md) {   
                    font-size: 26px !important;
                    line-height: 28px !important;
                    letter-spacing: 0.52px !important;
                }
                @include media-breakpoint-between(md, lg) {
                    font-size: 32px !important;
                    line-height: 48px !important;
                }
                @include media-breakpoint-between(lg, xl) {
                    font-size: 48px !important;
                    line-height: 48px !important;
                }
            }

            .hero-description {
                font-size: 1.275rem;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                @include media-breakpoint-between(md, lg) {
                    font-size: 22px !important;
                    line-height: 32px !important;
                }
                @include media-breakpoint-between(lg, xl) {
                    font-size: 28px !important;
                    line-height: 38px !important;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 42px;
                }
            }
        }
    }
       
    .swiper-pagination {
        gap: 10px;
        position: absolute;
        bottom: 34%;
        left: 16px;
        z-index: 1;
        @include media-breakpoint-up(md) {
            bottom: 20px;
            left: 50%;
        }
        @include media-breakpoint-up(xl) {
            bottom: 48px;
        }
    
       .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        background-color: $secondary;
        border-radius: 8px;
        &.swiper-pagination-bullet-active {
            width: 40px;
            background-color: $primary;
        }
       } 
    
    }

    .btn-scroll-down {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: solid #FFFFFF 1px;
        bottom: 2rem;
        left: 8.5%;
        z-index: 100;
        background-color: transparent;

        &:focus-visible {
            outline: var(--bs-primary) auto 1px !important;
        }
    }
    .swiper-button-disabled{
        opacity: .4;
        img{
            opacity: .4;
        }
    }
    .swiper-button-prev {
        cursor: pointer;
        position: absolute;
        top: calc( 50% - 22px);
        left: calc(8.333334%);
        z-index: 1;
        background-color: transparent;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $white;
        }
        path{
            fill:  $white;
        }
        &.button-gallery-flip{
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        position: absolute;
        cursor: pointer;
        transform: rotate(180deg);
        top: calc( 50% - 22px);
        right: calc(8.333334%);
        z-index: 1;
        background-color:transparent;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $white
        }
        path{
            fill: $white
        }
        &.button-hero-flip{
            transform: rotate(-360deg);
        }
       
    }
}