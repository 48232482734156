.image-card-widget {
    position: relative;
    overflow-x: hidden;
    overflow: hidden;

    .image-card-content {
        margin-bottom: 48px;

        .card-image-title {
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
            margin-bottom: 8px;

            @include media-breakpoint-down(md) {
                font-size: 36px;
                line-height: 42px;
            }
        }

        .card-image-description {
            p {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
    }

    &.container-fluid {
        padding-right: 0 !important;
        padding-left: 0 !important;

        @include media-breakpoint-down(md) {
            padding-right: 8px !important;
            padding-left: 8px !important;
        }
    }

    .image-card-carousel {
        overflow: visible;

        &.container {
            padding: 0 8px;
        }
    }

    .swiper-button-disabled {
        svg {
            opacity: .4;
        }
    }

    .swiper-button-prev {
        cursor: pointer;
        position: absolute;
        bottom: calc(50% - 50px + 22px);
        left: 0;
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;

        circle {
            stroke: $color-school;
        }

        path {
            fill: $color-school;
        }

        &.button-gallery-flip {
            transform: rotate(180deg);
        }

        @media (max-width:1440px) {
            left: 16px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .swiper-button-next {
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: calc(50% - 50px + 22px);
        z-index: 1;
        border-radius: 50%;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        transform: rotate(180deg);

        circle {
            stroke: $color-school;
        }

        path {
            fill: $color-school;
        }

        &.button-gallery-flip {
            transform: rotate(-360deg);
        }

        @media (max-width:1440px) {
            right: 16px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        img {
            transform: rotate(180deg);
        }
    }

    .image-card-slide {
        width: 347px !important;
        background-color: $white;
        border-radius: 8px;
        height: auto;
        position: relative;
        transition: .3s;
        &:hover{
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
            .card-image{
                img{
                    transform: scale(1.1);
                }
            }
        }

        .card-title {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 28px;
            color: $color-school;
            overflow: hidden;
            max-height: 60px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

            @include media-breakpoint-down(md) {
                height: unset;
            }
        }

        .card-description p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            overflow: hidden;
            max-height: 145px;
            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            color: $black;
            margin-bottom: 0;
        }

        .card-image {
            overflow: hidden; 
            clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
            border-radius: 8px 8px 0 0;
            img {
                width: 100%;
                height: 298px;
                object-fit: cover;
                transition: 0.4s;
            }

            &.card-image-flip {
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 50%);
            }
        }
        .cta-placeholder{
            height: 48px;
        }
        .cta-container {
           position: absolute;
           bottom: 32px;
           left: 16px;

            span {
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.32px;
                color: $color-school;
                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    -webkit-mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                    mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                    -webkit-mask-size: cover;
                    mask-size: cover;
                    width: 7px;
                    height: 12px;
                    background-color: $color-school;
                    display: inline-block;
                    margin-left: 10px;
                    padding-top: 4px;
                    transition: .3s;
                }

                &:hover {
                    &::after {
                        margin-left: 16px;
                    }
                }

                &.button-outline-flip {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .image-card-footer {
        position: relative;

        .buttons-container {
            gap: 16px;
        }

        .progress-bar-container {
            input[type=range] {
                -webkit-appearance: none;
                appearance: none;
                width: 64px;
                height: 10px;
                background: var(--bs-primary);
                border-radius: 5px;
                // outline: none; 
                outline-offset: 2px;
                margin: 10px 0;
            }

            /* Stile del cursore */
            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
                width: 18px;
                height: 10px;
                background: $color-school;
                border-radius: 8px;
                cursor: pointer;
            }


            /* Stile della barra di scorrimento */
            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 10px;
                background-color: var(--bs-primary);
                border-radius: 5px;
                cursor: pointer;
            }

            /* Stile del cursore per Mozilla Firefox */
input[type=range]::-moz-range-thumb {
    width: 18px;
    height: 10px;
    background: $color-school; /* Corretto in var() */
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

/* Stile della barra di scorrimento per Mozilla Firefox */
input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    background-color: var(--bs-primary);
    border-radius: 5px;
    cursor: pointer;
}

/* Stile del cursore per Microsoft Edge */
input[type=range]::-ms-thumb {
    width: 18px;
    height: 10px;
    background: var(--color-school); /* Corretto in var() */
    border-radius: 8px;
    cursor: pointer;
    margin-top: 0px; /* Per allineare il cursore alla barra in Edge */
}

/* Stile della barra di scorrimento per Microsoft Edge */
input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    background-color: transparent; /* Necessario per rendere visibili gli pseudo-elementi */
    border-color: transparent;
    color: transparent;
    cursor: pointer;
}

/* Stile di fallback per la barra di scorrimento in Edge */
input[type=range]::-ms-fill-lower {
    background-color: var(--bs-primary);
    border-radius: 5px;
}

input[type=range]::-ms-fill-upper {
    background-color: var(--bs-primary);
    border-radius: 5px;
}
        }
    }

    .image-card-carousel-mobile {
        .text-card-mobile {
            padding-right: 16px;
            min-height: 160px;

            .card-description p {
                color: $black;
            }

            .cta-container {
                height: 20px;

                span {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 0.32px;
                    color: $color-school;
                    display: flex;
                    align-items: center;

                    &::after {
                        content: '';
                        -webkit-mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                        mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                        -webkit-mask-size: cover;
                        mask-size: cover;
                        width: 7px;
                        height: 12px;
                        background-color: $color-school;
                        display: inline-block;
                        margin-left: 10px;
                        padding-top: 4px;
                        transition: .3s;
                    }

                    &:hover {
                        &::after {
                            margin-left: 16px;
                        }
                    }

                    &.button-outline-flip {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }

                }
            }

            .card-title {
                font-weight: bold;
                font-size: 1.37rem;
                line-height: 30px;
                color: $color-school;
                overflow: hidden;
                max-height: 64px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                margin-bottom: 8px;
            }

            .card-description p {
                font-size: 15px;
                line-height: 19px;
                overflow: hidden;
                max-height: 190px;
                display: -webkit-box;
                -webkit-line-clamp: 10;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                color: $black;
                margin-bottom: 0;
            }

        }

        .image-card-mobile {
            background: $white;
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            transition: .4s;
            img {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border-radius: 0 8px 8px 0;
                aspect-ratio: 1/1;
                object-fit: cover;
                -webkit-mask-image: url("../../../public/images/image-card-mask.png");
                mask-image: url("../../../public/images/image-card-mask.png");
                -webkit-mask-position: center;
                mask-position: center;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                transition: .4s;
            }
            &:hover{
                img{
                transform: scale(1.1) ;
                -webkit-mask-size: 90% 90%;
                mask-size: 90% 90%;
                }
                &.d-flex{
                    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);

                }
            }

            &.image-card-mobile-flip {
                img {
                    -webkit-mask-image: url("../../../public/images/image-card-mask-mobile.png");
                    mask-image: url("../../../public/images/image-card-mask-mobile.png");
                }
            }
        }

    }
    .card-description img{
max-width: 100%;
    }
}