.widget-partnership-wrapper {
    padding-left: 0px;
    padding-left: 0.5rem;
    .swiper-button-disabled{
        svg{
            opacity: .4;
        }
    }
    .swiper-button-prev{
        cursor: pointer;
        width: auto;
        position: absolute;
        bottom: calc(50% - 50px + 22px);
        left: 0px;
        cursor: pointer;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        z-index: 1;
        &.swiper-button-prev-row{
            left: 33.333%;
            @include media-breakpoint-down(lg) {
                left: 50%;
            }
        }
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        @media (max-width:1440px)  {
            left: 16px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        &.button-gallery-flip{
                transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        position: absolute;
        cursor: pointer;
        width: auto;
        right: 0px;
        bottom: calc(50% - 50px + 22px);
        transform: rotate(180deg);
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        &.button-gallery-flip{
            transform: rotate(360deg);
        }
        @media (max-width:1440px) {
            right: 16px;
            }
        @include media-breakpoint-down(md) {
                display: none;
            }
    }
    .partnership-title {
        font-size: 2rem !important;
        font-weight: 700 !important;
        line-height: 42px !important;
        color: $color-school;
        
        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
            font-size: 26px !important;
        }
    }

    .partnership-title-padding {
        padding-left: 170px;
        @include media-breakpoint-down(md) {
            padding-left: 16px;
        }
    }

    .partnership-partners-wrapper {
        height: 124px;
        @include media-breakpoint-down(md) {
            height: 100px;
        }
        .partner-card {
            width: 347px;
            border-radius: 8px;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(md) {
                width: 200px;
                height: 100px;
            }

            img {
                width: 300px;
                height: 79px;
                object-fit: contain;

                @include media-breakpoint-down(md) {
                    width: 150px;
                    height: 76px;
                }
            }
        }

    }
}