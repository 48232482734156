
.gallery-carousel{
    position: relative;
    &.container-fluid{
        padding-right: 0 !important;
        padding-left: 0 !important;
          @include media-breakpoint-down(md) {
        padding-right: 16px !important;
        padding-left: 16px !important;
    }
    }
    .swiper-pagination{
        gap: 5px;
        margin-top: 16px;
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
       .swiper-pagination-bullet{
        height: 10px;
        width: 10px;
        background-color: $primary;
        border-radius: 8px;
        &.swiper-pagination-bullet-active{
            width: 40px;
            background-color: $color-school;
        }
       } 
    
    }
    .swiper-slide{
        border-radius: 8px;
        p{
            font-size: 16px;
            line-height: 18px;
            margin-top: 24px;
            margin-bottom: 0;
          }
        &.gallery-slide{
            width: 58.33333% !important;
            border-radius: 8px;
            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
            .gallery-image-wrapper{
          border-radius: 8px;
          overflow: hidden;
           img{
            width: 100%;
            aspect-ratio: 5/3;
            object-fit: cover;
            height: auto;
            border-radius: 8px;
            @include media-breakpoint-down(md) {
                aspect-ratio: 358/255;
            }
           }
  
        }
        &.swiper-slide-active{
        .gallery-image-wrapper{
            position: relative;
        .overlay-slide {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            filter: unset;
            transition: background-color 0.3s ease;
            border-radius: 8px;
            z-index: 1;
           }
        }
        }
           &:not(.swiper-slide-active){
            .gallery-image-wrapper{
                position: relative;
            img{
                filter: blur(2px);
                backdrop-filter: blur(8px);
            }
          .overlay-slide {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.20);
            border-radius: 8px;
            z-index: 1;
            @include media-breakpoint-down(md) {
                background-color: transparent;
            }
        }
        }
    }

    }

    }
    .swiper-button-disabled{
        opacity: .4;
        img{
            opacity: .4;
        }
    }
    .swiper-button-prev {
        cursor: pointer;
        position: absolute;
        top: calc( 50% - 22px);
        left: calc(8.333334%);
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        &.button-gallery-flip{
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        position: absolute;
        cursor: pointer;
        transform: rotate(180deg);
        top: calc( 50% - 22px);
        right: calc(8.333334%);
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        &.button-gallery-flip{
            transform: rotate(-360deg);
        }
       
    }
}
.gallery-single-image{
 @include media-breakpoint-down(md) {
        padding-right: 16px;
        padding-left: 16px; 
    }
    img{
        width: 100%;
        height: auto;
        border-radius: 8px;
        aspect-ratio:  1193 / 610;
        object-fit: cover;
        @include media-breakpoint-down(md) {
            aspect-ratio: 358/255;
        }
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px; 
        margin-top: 16px;
        margin-bottom: 0;
    }

}
.gallery-double-image{
    flex-wrap: nowrap;
    gap: 16px;
    .row{
        width: 100%;
       & > * {
        @include media-breakpoint-down(md) {
        padding: 0;
        }
       }
        @include media-breakpoint-down(md) {
            width: 100%;
           gap:16px;
           padding: 0;
           margin: 0;
        }
    }
    @include media-breakpoint-down(md) {
        padding-right: 16px;
        padding-left: 16px; 
        flex-wrap: wrap;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 18px; 
        margin-top: 16px;
        margin-bottom: 0;
    }
    .double-image-wrapper{
      
        img{
            aspect-ratio: 589/610;
            width: 100%;
            object-fit: cover;
            height: auto;
            border-radius: 8px;
            @include media-breakpoint-down(md) {
                aspect-ratio: 358/371;
            }
        }
    }
}