.sub-header-wrapper {
    height: 50px;
    font-size: 16px;
    font-weight: 425;

    .custom-list {
        gap: 100px;
        list-style: none;
        @include media-breakpoint-down(md) {
            gap: 40px;
        }

        li {
            display: inline-block;
            vertical-align: middle;

            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 16px;
            }

            .subheader-link {
                line-height: 100%;
                display: flex;
                align-items: center;
                white-space: nowrap;
                &:focus-visible {
                    outline: 2px solid #FFFFFF !important;
                }
            }
            
        }
    }

}

.sub-header-lock {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 1001;

    @include media-breakpoint-down(xl) {
        top: 68px;
        z-index: 1001;
    }
}
