.widget-table-desktop {
    .table {
        border-radius: 8px !important;
        overflow: hidden;
        --bs-table-striped-bg: white;
        --bs-table-bg: var(--isp-light-secondary);

        thead {
            padding-left: 52px !important;

            tr {
                border-style: none !important;

                th {
                    border-style: none !important;
                }
            }

            tr:first-child {
                th {
                    background-color: $color-school !important;
                    color: $white !important;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32px;
                }
            }

            tr:nth-child(2) {
                th {
                    background-color: $color-school-light !important;
                    color: $color-school !important;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32px;
                }

            }
        }

        tbody {
            tr {
                border: none;

                .table-row-first-element {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32px;
                }

                td {
                    border: none;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    vertical-align: middle;
                }

                .widget-table-second-row {
                    background-color: $color-school-light !important;
                    color: $color-school !important;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32px;
                }
            }
        }
    }
}

.widget-table-mobile {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    table {
        margin-bottom: 0px;
    }
    
    .table-wrapper {
        overflow-x: auto;
        border-radius: 8px;
    }

    .sticky-th {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: $color-school;
        border: none;
        color: $white;
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
    }

    .table-mobile-second-column {
        background-color: $color-school-light;
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        color: $color-school;
    }

    .table-even-column {
        background-color: $light-secondary;
    }

    .table-text-bold {
        font-weight: 700;
    }

    td {
        padding: 0px;
        vertical-align: middle;
        text-align: center;
        border-style: none;

        p {
            margin: 0px;
            padding: 16px 8px;
            text-align: center;
        }
    }

    tr {
        padding: 0px;
        vertical-align: middle;
        text-align: center;
        border-style: none;

        p {
            margin: 0px;
            padding: 16px 8px;
            text-align: center;
        }
    }
}