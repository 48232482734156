.chessboard-widget{
    overflow: hidden;
    .chessboard-row{
        padding: 0 !important;
        .chessboard-text-container {
            @include media-breakpoint-down(lg) {
                padding-top: 16px;
                padding-bottom: 48px;
            }
            p, ul {
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
            }
        }
        .chessboard-title{
                font-weight: bold;
                font-size: 3rem;
                line-height: 60px;
                @include media-breakpoint-down(lg) {
                    font-weight: bold;
                    font-size: 2.25rem;
                    line-height: 42px;
                }
        }
        .enquire-description {
            a {
                color: $color-school;
            }
            img{
                max-width: 100%;
            }
        }
        &.flex-row-reverse{
            .chessboard-image-container{
            padding-left: 8px;
            padding-right: 0px;
            @include media-breakpoint-down(lg) {
                padding-left: 0px;
            }

            }
           .chessboard-text-container{
            padding-right: 80px;
            padding-left: 0px;
            @include media-breakpoint-down(lg) {
                padding-right: 0px;
                padding-left: 0px;
            }
           } 
        }
        &.flex-row{
            .chessboard-image-container{
            padding-right: 8px;
            padding-left: 0px;
            @include media-breakpoint-down(lg) {
                padding-right: 0px;
            }
            }
           .chessboard-text-container{
            padding-left: 80px;
            padding-right: 0px;
            @include media-breakpoint-down(lg) {
                padding-right: 0px;
                padding-left: 0px;
            }
           } 
        }
        .cta-container{
            margin-top: 48px;
            @include media-breakpoint-down(lg) {
                margin-top: 32px;
            }
        }
    }
    .chessboard-image-container{
        img{
            aspect-ratio: 1/1;
            object-fit: cover;
            width: 100%;
            height: auto;
            border-radius: 8px;
        }
        &.ratio-16-9{
            img{
                aspect-ratio: 16/9;
            }
        }
        &.ratio-4-3{
            img{
                aspect-ratio:4/3;
            }
        }
    }
}
.chessboard-content{
    @include media-breakpoint-down(lg) {
        justify-content: center;
    }
}