.image-widget-container {
    width: 100%;
    max-width: 1194px;
    height: 710px;

    @include media-breakpoint-down(xl) {
        height: 500px;
    }

    .image-wrapper-overlay {
        img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }

    .image-widget-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        a {
            img {
                width: 50px;
                height: 50px;
                z-index: 10000 !important;
            }
        }

        .widget-image-icon-wrapper {
            padding-right: 24px;
            padding-bottom: 24px;
        }
    }

    .overlay-transparent {
        background: transparent;
    }   

    .overlay-color {
        background: #0000008C; 
    }

}

