.hero-slim-wrapper-desktop {
    img {
        height: 350px;
        width: 100%;
        object-fit: cover;
    }

}

.hero-slim-wrapper-mobile {
    img {
        height: 250px;
        width: 100%;
        object-fit: cover;
    }
}

.hero-slim-video-wrapper {
    height: 350px;

    @include media-breakpoint-down(md) {
        height: 250px;
    }
}

.landing-page {
    .hero-video-title.h1 {
        font-weight: bold;
        font-size: 3rem;
        line-height: 60px;
        @include media-breakpoint-down(md) {
            font-weight: bold;
            font-size: 1.62rem;
            line-height: 28px;
        }
    }
}

.hero-slim-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000008C;
    z-index: 10;
}