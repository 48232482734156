.color-school-light-pillars{
    background-color: $color-school-light;
}
.column-pillars{
    flex-direction: column;
    .widget-learning-cards-wrapper{
        overflow: auto;
    }
}
.intro-pillars-container{
    margin-bottom: 32px;
    @include media-breakpoint-down(md) {
        padding: 0 8px;
    }
.widget-timeline-title {
    color: $color-school;
    margin-bottom: 8px;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    word-wrap: break-word;
    @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
    }
}
.widget-learning-pillars-description{
    p{
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

}
}

.row-pillars{
    flex-direction: row;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
    .intro-pillars-container{
        align-items: start;
        margin-bottom: 0;
        padding-right: 3rem;
        @include media-breakpoint-down(md) {
            align-items: center;
            padding-right: 8px;
            padding-left: 8px;
        }
    }
    a{
        margin-top: 32px;
    }
}

.column-pillars{
    .intro-pillars-container{
       .widget-timeline-title{
        text-align: center;
       }
       .widget-learning-pillars-description p{
        text-align: center;
       }
    }
}

.widget-learning-cards-wrapper {
padding-top: 20px;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        padding-left: 0px !important;
        padding-right: 0px !important;
        overflow-x: hidden !important;
    }

    .widget-learning-cards-container {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .widget-learning-card-wrapper {
        padding-right: 24px;
        @include media-breakpoint-down(md) {
            padding-right: 8px;
            padding-left: 8px;
            margin-bottom: 24px;
            width: 100%;
        }

        .widget-learning-icon-card {
            width: 268px;
            padding-bottom: 32px;
            border-radius: 8px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            overflow: hidden;
            background: #fff;
            .widget-learning-header-icon img,
            .widget-learning-header-icon svg{
                position: relative;
                z-index: 1;
                width: 58px;
                height: 58px;
            }

            .gradient-learning-card-background {
                top: 0px;
                left: 0px;
                height: 204px;
                width: 100%;
                background: linear-gradient(150deg, $accent-light 52%, $white 52%); 
                z-index: 0;

                @include media-breakpoint-down(md) {
                    height: 227px;
                }
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .learning-icon-card-title {
                position: relative;
                z-index: 1;
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                color: $color-school;
            }
            .learning-icon-card-paragraph {
                position: relative;
                z-index: 1;
                a {
                    color: $color-school;
                }
                img, svg{
                    max-width: 100%;
                }
            }
        }
    }
}
.video-pillars {
    width: 1154px;
    @include media-breakpoint-down(xl) {
        width: 100%;
    }
}
.video-slider-wrapper-pillars{
    .button-volume{
        border: unset;
        background: $white;
        width: 44px;
        height: 44px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        margin-left: 28px;
        padding: 7px 12px;
        span:after {
            content: "";
            -webkit-mask: url("../../../public/images/volume-on.svg") no-repeat 50% 50%;
            mask: url("../../../public/images/volume-on.svg") no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            width: 20px;
            height: 19px;
            background-color: var(--isp-color-school);
            display: inline-block;
            transition: 0.3s;
        }
        &.volume-off{
            span:after {
                content: "";
                -webkit-mask: url("../../../public/images/volume-off.svg") no-repeat 50% 50%;
                mask: url("../../../public/images/volume-off.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                width: 20px;
                height: 19px;
                background-color: var(--isp-color-school);
                display: inline-block;
                transition: 0.3s;
            }
        }
    }
}