.color-school-light-timeline{
    background-color: $color-school-light;
}
.timeline-widget{
    &.column-timeline{
        flex-direction: column;
        align-items: center;
        .intro-timeline-container{
            text-align: center;
            @include media-breakpoint-down(md) {
                padding: 0 16px;
            }
        }
        .widget-learning-pillars-description{
            p{
                text-align: center;
            }
        }
    }
    &.row-timeline{
        flex-direction: row;
        align-items: center;
        @include media-breakpoint-down(md) {
           flex-direction: column;
         }
        .intro-timeline-container{
            align-items: start;
            .btn-primary{
                margin-top: 32px;
            }
        }
    }

    .intro-timeline-container{
        margin-bottom: 32px;
        @include media-breakpoint-down(md) {
            align-items: center !important;
            margin-bottom: 0;
         }
         .btn.btn-primary{
            @include media-breakpoint-down(md) {
              margin-top: 32px;
             }
         }
        .widget-timeline-title {
            color: $color-school;
            margin-bottom: 8px;
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
            word-wrap: break-word;
            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 36px;
                font-weight: 700;
                line-height: 42px;
            }
        }
        .widget-learning-pillars-description{
            p{
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px
                }
            }
        
        }
    }
.column-button{
    margin-top: 8px;
}
.widget-timeline-cards-wrapper {
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        padding-left: 0px !important;
        padding-right: 0px !important;
        overflow-x: hidden !important;
    }
   
    .widget-timeline-card-wrapper {
padding-top: 20px;
        @include media-breakpoint-down(md) {
            width: 100%;
            padding-top: 24px ;
        }

        .widget-timeline-icon-card {
            background-color: $white;
            width: 323px;
            padding-bottom: 32px;
            border-radius: 8px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            overflow: hidden;
            transition: all 0.3s ease;
            &:hover{
                transform: translateY(-10px);
                box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
            }

            .widget-timeline-header-icon img,
            .widget-timeline-header-icon svg{
                width: 48px;
                height: 48px;
                position: relative;
                z-index: 1;
            }

            .gradient-card-background {
                top: 0px;
                left: 0px;
                height: 204px;
                width: 100%;
                background: linear-gradient(150deg, $accent-light 52%, $white 52%);
                z-index: 0;

                @include media-breakpoint-down(md) {
                    height: 227px;
                }
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .timeline-icon-card-title {
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                color: $color-school;
                position: relative;
                z-index: 1;
            }
            .timeline-icon-card-paragraph {
                position: relative;
                z-index: 1;
                a {
                    color: $color-school;
                }
                img, svg{
                    max-width: 100%;
                }
            }
        }

        .widget-timeline-icon-img {
            width: 323px;

            svg {
                // width: 48px;
                // height: 48px;
                position: relative;
                z-index: 1;

                g {
                    #Vector {
                        fill: $accent;
                    }

                    #Vector_2 {
                        fill: $color-school;
                    }

                    line {
                        stroke: $accent;
                    }
                }
            }
        }

        .widget-timeline-icon-img-mobile {
            svg {
                // width: 48px;
                // height: 48px;

                g {
                    #Vector {
                        fill: $accent;
                    }

                    #Vector_2 {
                        fill: $color-school;
                    }
                }
            }
        }

        .widget-timeline-icon-index {
            width: 323px;
            color: $color-school;
        }

        .widget-timeline-icon-index-mobile {
            color: $color-school;
        }


    }


}

.widget-timeline-icon-line {
    border-bottom: solid $body-tertiary-bg 2px;
    z-index: 0;
}

.timeline-line-padding {
    bottom: 202px;
    &.line-with-cta{
        bottom: 274px;
    }
}

.timeline-line-no-padding {
    bottom: calc(202px - 104px);
    &.line-with-cta{
        bottom: calc(274px - 104px);
    }
}
}
.row-line-timeline {
    right: 0;
}