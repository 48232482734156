@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/raleway/Raleway.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/raleway/Raleway.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/raleway/RalewayItalic.ttf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "FilsonPro";
    src: url("../../assets/fonts/filson-pro/FilsonProBold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FilsonPro";
    src: url("../../assets/fonts/filson-pro/FilsonProBook.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FigGrotesk";
    src: url("../../assets/fonts/fig-grotesk/FigGrotesk0.3-Bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FigGrotesk";
    src: url("../../assets/fonts/fig-grotesk/FigGrotesk0.3-Book.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MuseoSans";
    src: url("../../assets/fonts/museo-sans/museo-sans-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MuseoSans";
    src: url("../../assets/fonts/museo-sans/museo-sans-regular.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoKufiArabic";
    src: url("../../assets/fonts/noto-kufi-arabic/NotoKufiArabic-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoKufiArabic";
    src: url("../../assets/fonts/noto-kufi-arabic/NotoKufiArabic-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Chronicle";
    src: url("../../assets/fonts/chronicle/chronicle-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Chronicle";
    src: url("../../assets/fonts/chronicle/chronicle-normal.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair";
    src: url("../../assets/fonts/playfair/playfair-regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Playfair";
    src: url("../../assets/fonts/playfair/playfair-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MrsEaves";
    src: url("../../assets/fonts/mrs-eaves/mrs-eaves-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MrsEaves";
    src: url("../../assets/fonts/mrs-eaves/mrs-eaves-normal.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src: url("../../assets/fonts/rubik/rubik.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rubik";
    src: url("../../assets/fonts/rubik/rubik.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/lato-regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../../assets/fonts/lato/lato-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("../../assets/fonts/open-sans/open-sans-regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "OpenSans";
    src: url("../../assets/fonts/open-sans/open-sans-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../assets/fonts/montserrat/montserrat-regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../assets/fonts/montserrat/montserrat-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MinionPro";
    src: url("../../assets/fonts/minion-pro/minion-pro-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MinionPro";
    src: url("../../assets/fonts/minion-pro/minion-pro-regular.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "CollegeBlock";
    src: url("../../assets/fonts/college-block/college-block.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "CollegeBlock";
    src: url("../../assets/fonts/college-block/college-block.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Calibri";
    src: url("../../assets/fonts/calibri/calibri-regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Calibri";
    src: url("../../assets/fonts/calibri/calibri-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Arial";
    src: url("../../assets/fonts/arial/arial.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Arial";
    src: url("../../assets/fonts/arial/arial-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RotisSansSerif";
    src: url("../../assets/fonts/rotis-sans-serif/rotis-sans-serif.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RotisSansSerif";
    src: url("../../assets/fonts/rotis-sans-serif/rotis-sans-serif-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Palatino";
    src: url("../../assets/fonts/palatino/Palatino.ttc") format("truetype-collection");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Palatino";
    src: url("../../assets/fonts/palatino/Palatino.ttc") format("truetype-collection");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSerif";
    src: url("../../assets/fonts/noto-serif/noto-serif-regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSerif";
    src: url("../../assets/fonts/noto-serif/noto-serif-bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}