.header-landing {
    .navbar {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0;
    }

    .section-mobile-logo {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0;
    }

    .header-opacity{
        background-color: rgba(51, 51, 51, 0.6);
    }
}