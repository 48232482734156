.editorial-tabs-widget{
    @include media-breakpoint-down(md) {
      
        overflow: hidden;
    }
    .editorial-tab-intro{
        margin-bottom: 48px;
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
            padding: 0 16px;
        }
        .editorial-tab-title{
            .h2{
                color: $color-school;
            }
        }
        .editorial-tab-description{
            p{
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }
   .button-editorial-tabs-container{
        white-space: nowrap;
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 48px;
        width: 100%;

        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
            width: 100%;
        }
    &::-webkit-scrollbar {
        display: none; 
    }

    .nav-pills.editorial-tabs-list  {
        gap: 0 16px;
    }
    .nav-item{
        display: inline-block; 
        white-space: nowrap;
        &:first-child{
            margin-left: 16px;
        }     
        &:last-child{
            margin-right: 16px;
        }     

    }
    .nav-link{
        color: $black;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 14px;
        border-radius: 8px;
        background-color: unset;
        border: 1px solid $color-school;

        &.active{
            color: $white;
            background-color: $color-school;
        }
    }
   } 
   .editorial-tabs-content{
    .text-tab-content{
        padding-right: 80px;
        @include media-breakpoint-down(lg) {
            padding-right: 40px;
        }
        @include media-breakpoint-down(md) {
            padding-right: 16px;
            padding-left: 16px;
            margin-top: 16px;
        }
        h1, h2, h3, h4, h5, h6{
            color: $color-school;
        }
        a{
            text-decoration: underline;
            color: $color-school;
        }
        p{
            padding-bottom: 16px;
            font-size: 20px;
            line-height: 32px;
            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 30px
            }
        }
        ul, ol {
            padding-bottom: 16px;
            padding-left: 0;
                li{
                    font-size: 20px;
                    line-height: 32px;
                    padding-bottom: 16px;
                    margin: 0 24px;
                    padding: 0 4px;
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        line-height: 30px
                    }
                     &::marker {
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
    }
    .text-tab-content > :last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .image-tab-content{
        img{
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            // height: auto;
            border-radius: 8px;
        }
        &.ratio-16-9{
            img{
                aspect-ratio: 16/9;
            }
        }
        &.ratio-4-3{
            img{
                aspect-ratio:4/3;
            }
        }
        @include media-breakpoint-down(md) {
            padding-right: 16px;
            padding-left: 16px;
        }
    }
   }
}