.widget_tab {
    height: 79px;
}
.isp-scrollspy,
#isp-scrollspy,
#isp-scrollspy-mobile {
    top: 0px;
    z-index: 10;
    width: 100%;
    max-width: 100vw;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: $white;
    .nav-pills {
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .nav-item {
        border-radius: 8px;
        border: 1px solid $color-school;
        margin: 8px;
        cursor: pointer;
        @include media-breakpoint-down(md) {
            &:first-child {
                margin-left: 16px;
            }
            &:last-child {
                margin-right: 16px;
            }
        }
        .nav-link {
            font-size: 14px;
            color: $black;
            padding: 0;
            padding: 12px 16px;
            display: block;
            white-space: nowrap;
            &.active {
                background-color: $color-school;
                color: $white;
            }
        }
    }
}
