.quote-widget {
    position: relative;
    &.widget-manual {
        @include media-breakpoint-down(lg) {
            padding-right: 16px;
            padding-left: 16px;
        }
    }
    .manual-quote {
        &.container-quote-text {
            padding: 48px 104px;
            background-color: $accent-light;
            @include media-breakpoint-down(lg) {
                padding: 48px 16px;
            }
            .quote-text {
                gap: 40px;
                @include media-breakpoint-down(lg) {
                    gap: 24px;
                }
                p {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 28px;
                    color: $color-school;
                    letter-spacing: 0.48px;
                    margin-bottom: 0;
                }
                .quote-image {
                    width: 78px;
                    height: auto;
                    path {
                        fill: $accent;
                        width: 78px;
                        height: auto;
                    }
                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        justify-content: end;
                        &.svg-flip {
                            justify-content: start;
                        }
                    }
                }
            }
        }
    }
    .data-object-quote {
        min-height: 756px;
        overflow-x: hidden;
        .circle-background {
            position: absolute;
            height: 100%;
            width: auto;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background-color: $accent-light;
            top: 0;
            z-index: 0;
            &.flex-row {
                right: -12%;
                @include media-breakpoint-down(lg) {
                    right: 16px;
                }
            }
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        .quote-row {
            &.flex-row {
                .col-text {
                    @include media-breakpoint-down(lg) {
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                }
            }
            .col-text {
                position: relative;
                z-index: 1;
                @include media-breakpoint-down(lg) {
                    padding-top: 32px;
                }
                .quote-description {
                    position: relative;
                    color: $color-school;
                    font-family: $font-family-heading;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 42px;
                    padding-right: 78px;
                    @include media-breakpoint-down(lg) {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 28px;
                        padding-right: 0px;
                    }
                    p {
                        @include media-breakpoint-down(lg) {
                            width: 90%;
                        }
                    }
                    :last-child {
                        margin-bottom: 0;
                    }
                }
                .quote-image {
                    position: absolute;
                    right: 0;
                    bottom: -15px;
                    path {
                        fill: $accent;
                    }
                    @include media-breakpoint-down(lg) {
                        right: 16px;
                        width: 45px;
                        bottom: -10px;
                    }
                }
                .person-description {
                    font-size: 20px;
                    line-height: 32px;
                    gap: 8px;
                    padding-right: 110px;
                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                        line-height: 30px;
                        width: 100%;
                        padding-right: 0;
                    }
                }
                :not(.cta-container) > a {
                    text-decoration: underline;
                }
                .quote-title {
                    font-weight: bold;
                    font-size: 3rem;
                    line-height: 60px;
                    @include media-breakpoint-down(lg) {
                        font-weight: bold;
                        font-size: 2.25rem;
                        line-height: 42px;
                    }
                }
                ul {
                    list-style: none;
                    padding: unset;
                    margin: unset;
                    margin-bottom: 28px;
                    li {
                        font-size: 20px;
                        line-height: 32px;
                        padding-bottom: 12px;
                        margin: 12px 0px;
                        padding: 0 4px;
                        display: flex;
                        @include media-breakpoint-down(lg) {
                            font-size: 18px;
                            line-height: 30px;
                        }
                        &::before {
                            content: url("../../../public/images/bullet-list.svg");
                            display: inline-block;
                            margin-right: 12px;
                            padding-top: 4px;
                        }
                    }
                }
                ol {
                    padding: unset;
                    margin: unset;
                    margin-bottom: 28px;
                    padding-right: 28px;
                    padding-left: 28px;
                    li {
                        font-size: 20px;
                        line-height: 32px;
                        padding-bottom: 12px;
                        margin: 12px 0px;
                        padding: 0 4px;
                        @include media-breakpoint-down(lg) {
                            font-size: 18px;
                            line-height: 30px;
                        }
                        &::marker {
                            color: $accent;
                            margin-right: 12px;
                            padding-top: 4px;
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }
            }
            .is-background-circle-1 {
                .circle-container {
                    padding-right: 88px;
                    @include media-breakpoint-down(lg) {
                        padding-right: 0;
                    }
                }
                .cta-container {
                    .quote-cta {
                        cursor: pointer;
                        color: $color-school;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 0.32px;
                        display: flex;
                        align-items: center;
                        &::after {
                            content: "";
                            -webkit-mask: url("../../../public/images/arrow-right-color-school.svg")
                                no-repeat 50% 50%;
                            mask: url("../../../public/images/arrow-right-color-school.svg")
                                no-repeat 50% 50%;
                            -webkit-mask-size: cover;
                            mask-size: cover;
                            width: 7px;
                            height: 12px;
                            background-color: $color-school;
                            display: inline-block;
                            margin-left: 10px;
                            padding-top: 4px;
                            transition: 0.3s;
                        }
                        &:hover {
                            &::after {
                                margin-left: 16px;
                            }
                        }
                    }
                    &.button-outline-flip {
                        a {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .col-images {
                .circle-container {
                    width: 100%;
                }
                .circle-image {
                    width: 75%;
                    picture {
                        width: 100%;
                    }
                    img {
                        width: 100%;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        border-radius: 50%;
                        height: auto;
                        position: relative;
                        z-index: 1;
                    }
                    padding-bottom: 68px;
                }
                .circle-color {
                    width: 25%;
                    div {
                        width: 100%;
                        height: auto;
                        position: relative;
                        z-index: 1;
                        border-radius: 50%;
                        height: auto;
                        aspect-ratio: 1/1;
                        background-color: $accent;
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            min-height: unset;
        }
    }
}
