.breadcrumbs-isp{
    padding-top: 48px;
    padding-bottom: 48px;
    @include media-breakpoint-down(md) {
        padding: 24px 16px;
    }
    .my-breadcrumbs  a:nth-child(2)::before {
        content: ""; /* Imposta il contenuto del pseudo-elemento su una stringa vuota */
    }
    .flip-breadcrumbs{
        a{
        &::after{
            transform: rotate(180deg);
        }
    }
    }
    ul{
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }
    a{
        font-size: 16px;
        line-height: 16px;
        color: $black;
        display: flex;
        align-items:center;
        font-weight: 500;
        &::after{
            content: "";
            -webkit-mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
            mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            width: 7px;
            height: 12px;
            background-color: $black;
            display: inline-block;
            margin: 0 10px 0 10px;
            padding-top: 4px;
            transition: 0.3s;
        }
        &::before {
            @include media-breakpoint-down(md) {
            content: "..." !important;
            font-size: 16px;
            }
          }
        @include media-breakpoint-down(md) {
            font-size: 0;
        }
    }
    .my-breadcrumbs{
        color: $color-school;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
    }
    .breadcrumbs-home{
        &::before{
            content: "";
            -webkit-mask: url("../../../public/images/home.svg") no-repeat 50% 50%;
            mask: url("../../../public/images/home.svg") no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            width: 23px;
            height: 23px;
            background-color: var(--isp-color-school);
            display: inline-block;
            padding-top: 4px;
            transition: 0.3s;
            @include media-breakpoint-down(md) {
            width: 17px;
            height: 17px;
            }
        }
    }
}