.hero-video-wrapper {
    height: calc(100vh - 100px);
    height: calc((var(--vh, 1vh) * 100) - 100px);
    min-height: 600px;
    position: relative;

    @include media-breakpoint-down(xl) {
        height: calc(100vh - var(--isp-header-height-mobile));
        height: calc((var(--vh, 1vh) * 100) - var(--isp-header-height-mobile));
        min-height: 400px;
    }

    .hero-video-overlay {
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background: #0000008C;
        z-index: 10;

        .hero-video-wrapper-text {
            width: 60%;
            overflow-wrap: break-word;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .hero-video-content {
                font-size: 32px;
                font-weight: 400;
                line-height: 42px;
            }
        }

        .hero-video-title {
            @include media-breakpoint-down(md) {
                font-size: 26px !important;
                line-height: 28px !important;
                letter-spacing: 0.52px !important;
            }
        }
    }

    .container-hero-video-arrow-down {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: solid $white 1px;
        bottom: 2rem;
        left: 8.5%;
        z-index: 100;
        background-color: transparent;

        &:focus-visible {
            outline: var(--bs-primary) auto 1px !important;
        }

        @include media-breakpoint-down(xl) {
            left: 16px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .hero-video-fullscreen-btn {
        width: 44px;
        height: 44px;
        bottom: 2rem;
        right: 8.5%;
        z-index: 100;
        background-color: transparent;

        &:focus-visible {
            outline: var(--bs-primary) auto 1px !important;
            border-radius: 50%;
        }

        &:hover {
            cursor: pointer;
        }

        img.hero-video-fullscreen {
            width: 44px;
            height: 44px;
            
            @include media-breakpoint-down(xl) {
                right: 16px;
            }
        }
       
    }

}