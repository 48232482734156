// Override variables with pimcore configuration school
$accent: var(--isp-accent);
$color-school: var(--isp-color-school);
$accent-light: var(--isp-light-accent);
$color-school-dark: var(--isp-color-school-dark);
$color-school-light: var(--isp-color-school-light);
$primary: var(--bs-primary);
$light-secondary: var(--isp-light-secondary);
$font-family-base: var(--bs-body-font-family);
$font-family-heading: var(--bs-heading-font-family);
$link-color: var(--isp-color-school);
$nav-link-color: var(--isp-color-school);
$nav-link-hover-color: var(--isp-color-school);
$direction: var(--isp-direction);
$isRTL: var(--isp-is-rtl);
$mainButtonTextColor: var(--isp-main-button-color);
$backgroundColor: var(--isp-background-color);
$textColor: var(--isp-text-color);

body {
    margin: 0;
    // padding: 10px;
    padding-top: 68px;
    overflow-x: hidden;
    // position: relative;

    @include media-breakpoint-up(xl) {
        padding-top: 100px;
    }

    div,
    ul {
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
            /* WebKit (Chrome, Safari) */
        }
    }
}

.text-color-custom{
    color: $textColor;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.widget-padding {
    padding-top: 104px;
    padding-bottom: 104px;

    @include media-breakpoint-down(md) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

.widget-padding-top {
    padding-top: 104px;

    @include media-breakpoint-down(md) {
        padding-top: 48px;
    }
}

.widget-padding-bottom {
    padding-bottom: 104px;

    @include media-breakpoint-down(md) {
        padding-bottom: 48px;
    }
}

h1 {
    font-weight: bold;
    font-size: 3.75rem;
    line-height: 70px;
    font-family: $font-family-heading;

    @include media-breakpoint-down(md) {
        font-weight: bold;
        font-size: 2.62rem;
        line-height: 46px;
    }
}

h2 {
    font-weight: bold;
    font-size: 3rem;
    line-height: 60px;
    font-family: $font-family-heading;

    @include media-breakpoint-down(md) {
        font-weight: bold;
        font-size: 2.25rem;
        line-height: 42px;
    }
}

h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 42px;
    font-family: $font-family-heading;

    @include media-breakpoint-down(md) {
        font-weight: bold;
        font-size: 1.62rem;
        line-height: 28px;
    }
}

h4 {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 28px;
    font-family: $font-family-heading;

    @include media-breakpoint-down(md) {
        font-weight: bold;
        font-size: 1.37rem;
        line-height: 30px;
    }
}

a {
    // &:not(.btn):focus,
    letter-spacing: 0.32px;

    &:not(.btn):focus-visible {
        box-shadow: unset !important;
        outline: 2px solid $color-school !important;
        outline-offset: 3px;
        border-radius: 4px;
    }
}

.container-fluid {
    max-width: $container-fluid-max-width;
    margin: auto;
    // padding: 0;
}

.btn.btn-primary {
    letter-spacing: 0.32px;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-color: var(--isp-main-button-color);

    &:active {
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(255, 255, 255, 0.2) 100%
            ),
            var(--bs-primary);
    }

    &:hover {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
            ),
            var(--btn-primary-hover, var(--bs-primary));
        border-color: var(--bs-primary);
    }

    &:disabled {
        background: $body-tertiary-bg;
    }

    &:not(.btn-school-icon):focus-visible {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
            ),
            var(--btn-primary-hover, var(--bs-primary));
        border-color: var(--bs-primary);
        box-shadow: 0 0 0 0.1rem var(--bs-primary);
        opacity: 0.95;
    }
}

.btn.btn-secondary {
    letter-spacing: 0.32px;
    border-radius: $btn-border-radius;
    background-color: transparent;
    border: 1px solid $color-school;

    &:hover {
        background: $color-school-light;
    }

    &:active {
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(255, 255, 255, 0.2) 100%
            ),
            var(--color-school-light, #f5eeff);
    }

    &:disabled {
        border: 1px solid $body-tertiary-bg;
    }
}

.btn.btn-outline-primary {
    color: $white;

    &:active {
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(255, 255, 255, 0.2) 100%
            ),
            var(--bs-primary);
    }

    &:hover {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
            ),
            var(--btn-primary-hover, var(--bs-primary));
        border-color: var(--bs-primary);
    }

    &:disabled {
        background: $body-tertiary-bg;
    }
}

.btn.btn-school-icon {
    width: 46px;
    height: 46px;
    padding: 7px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 1px solid var(--bs-primary);
    background: $color-school-dark;

    &:hover {
        border-color: $color-school-light;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
            ),
            $color-school;
    }

    &:focus {
        border-color: var(--bs-primary);
        box-shadow: 0 0 0 0.1rem var(--bs-primary);
        opacity: 0.95;
    }
}

.btn-account {
    &:before {
        content: "";
        -webkit-mask: url("../../public/images/account-login-school.svg")
            no-repeat 50% 50%;
        mask: url("../../public/images/account-login-school.svg") no-repeat 50%
            50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        width: 18px;
        height: 19px;
        background-color: var(--isp-color-school);
        display: inline-block;
        margin-right: 10px;
        padding-top: 4px;
        transition: 0.3s;
        @include media-breakpoint-down(xl) {
            position: absolute;
            left: 0;
        }
    }
}

// Btn with arrow
.btn-arrow {
    color: $color-school;
    font-size: 1rem;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.32px;

    span:after {
        content: "";
        -webkit-mask: url("../../public/images/arrow-right-color-school.svg")
            no-repeat 50% 50%;
        mask: url("../../public/images/arrow-right-color-school.svg") no-repeat
            50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        width: 7px;
        height: 12px;
        background-color: var(--isp-color-school);
        display: inline-block;
        margin-left: 10px;
        padding-top: 4px;
        transition: 0.3s;
    }

    &:hover {
        span:after {
            margin-left: 16px;
        }
    }

    &.btn-arrow-flip {
        span:after,
        &:after {
            transform: rotate(180deg);
        }
    }
}

.btn-only-arrow {
    position: relative;
    &:after {
        content: "";
        -webkit-mask: url("../../public/images/arrow-right-color-school.svg")
            no-repeat 50% 50%;
        mask: url("../../public/images/arrow-right-color-school.svg") no-repeat
            50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        width: 8px;
        height: 12px;
        background-color: var(--isp-color-school);
        display: inline-block;
        margin-left: 10px;
        padding-top: 4px;
        transition: 0.3s;
        right: 10px;
    }

    &:hover {
        &:after {
            margin-left: 16px;
        }
    }

    &.btn-arrow-flip {
        &:after {
            transform: rotate(180deg);
        }
    }
}

.background-color-school {
    background: $backgroundColor;
}

.background-color-school-dark {
    background: $color-school-dark;
}

.background-color-school-light {
    background-color: $color-school-light;
}

.background-secondary-light {
    background-color: $light-secondary;
}

.background-accent-light {
    background-color: $accent-light;
}

.color-school-text {
    color: $color-school;
}

.custom-color-bullet {
    path {
        stroke: $accent;
        color: $accent;
    }
}

.cursor-pointer {
    cursor: pointer;
}

/* Form */
.form-control {
    border-radius: 4px;
    border: 1px solid $body-tertiary-bg;
    background: $white;
    height: 46px;
    padding: 15px 8px;
    outline-offset: 0;

    &:hover {
        background: $cultured;
    }

    &:focus,
    &:focus-visible {
        border: 2px solid $blue-input-focus;
        box-shadow: unset;
    }
}

.label-tag {
    background-color: $color-school;
    border-radius: 4px;
    color: $white;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18px;
    padding: 6px 10px;
    width: fit-content !important;

    @include media-breakpoint-down(md) {
        padding: 4px 8px;
    }
}

// CSS RTL version
.image-flip img {
    transform: scaleX(-1);
}

.svg-flip {
    transform: scaleX(-1);
}

@keyframes transitionLeftToRight {
    100% {
        transform: translate(0%);
    }
}

// modal with text+image or only test used in widgets: widget quote, learning pillars, timeline icon, timeline image, people
.modal.modal-widget-quote {
    background-color: rgba(0, 0, 0, 0.7);
    .modal-dialog {
        --bs-modal-width: 984px;
    }
    .close-modal-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 2;
    }
    .modal-image {
        -webkit-mask-image: url("../../public/images/quote-modal-desktop-mask.png");
        mask-image: url("../../public/images/quote-modal-desktop-mask.png");
        -webkit-mask-position: center;
        mask-position: left;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        position: relative;
        height: 600px;
        min-width: 40%;
        @include media-breakpoint-down(lg) {
            -webkit-mask-image: url("../../public/images/quote-modal-mobile-mask.png");
            mask-image: url("../../public/images/quote-modal-mobile-mask.png");
            width: 390px;
            height: 460px;
            -webkit-mask-size: 390px 460px;
            mask-size: 390px 460px;
        }
        @media (max-width: 576px) {
            width: 312px;
            height: 368px;
            -webkit-mask-size: 312px 368px;
            mask-size: 312px 368px;
        }
        img {
            aspect-ratio: 1/1;
            object-fit: cover;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @include media-breakpoint-down(lg) {
                aspect-ratio: 1/1;
                height: 460px;
            }
            @media (max-width: 576px) {
                height: 368px;
            }
        }
        &.modal-image-flip {
            -webkit-mask-image: url("../../public/images/quote-modal-desktop-mask-flip.png");
            mask-image: url("../../public/images/quote-modal-desktop-mask-flip.png");
            @include media-breakpoint-down(lg) {
                -webkit-mask-image: url("../../public/images/quote-modal-mobile-mask-flip.png");
                mask-image: url("../../public/images/quote-modal-mobile-mask-flip.png");
            }
        }
        &.modal-image-icon {
            background-color: $white;
            mask-image: none;
            .big-circle {
                height: min-content;
                position: absolute;
                top: 10%;
                background-color: var(--isp-color-school);
                width: 85%;
                right: 0%;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
            }
            .small-circle {
                position: absolute;
                bottom: 10%;
                left: -5%;
                width: 25%;
                height: min-content;
                aspect-ratio: 1 / 1;
                background-color: var(--isp-accent);
                border-radius: 50%;
            }
            img {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
                height: 65%;
            }
        }
    }
    .modal-text {
        padding: 48px 48px 48px 32px;
        @include media-breakpoint-down(lg) {
            padding: 32px 16px;
        }
        .modal-quote-name {
            font-size: 32px;
            font-weight: 700;
            line-height: 42px;
            color: $color-school;
            @include media-breakpoint-down(lg) {
                font-size: 26px;
                line-height: 28px;
                letter-spacing: 0.52px;
                margin-bottom: 8px;
            }
        }
        .modal-quote-job {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.48px;
            margin-bottom: 8px;
            @include media-breakpoint-down(lg) {
                font-size: 22px;
                line-height: 30px;
            }
        }
        .modal-quote-quote {
            font-size: 14px;
            line-height: 18px;
            @include media-breakpoint-down(lg) {
                margin-bottom: 0;
            }
        }
    }
}

// modal with video used in widgets: community voices, learning pillars, timeline icon, timeline image
.modal-quote.modal-video {
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    z-index: 9999;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include media-breakpoint-down(sm) {
        padding: 24px 16px;
        min-height: 100vh;
        height: auto;
    }

    .container-fluid {
        padding: 0 !important;
    }

    .close-modal-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
        z-index: 2;
        background-color: $color-school-light;
        height: 40px;
        width: 40px;
        border-radius: 50%;

        @include media-breakpoint-down(md) {
            position: fixed;
        }
        svg {
            width: 20px;
            height: auto;
            path {
                color: $color-school;
            }
        }
    }

    video {
        width: 1154px;
        @media screen and (max-width: 1154px) {
            width: 100%;
        }
    }

    .video-slider-wrapper {
        bottom: 44px;
        left: 12.5%;
        width: 75%;

        .button-volume {
            border: unset;
            background: $white;
            width: 44px;
            height: 44px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            margin-left: 28px;
            padding: 7px 12px;
            span:after {
                content: "";
                -webkit-mask: url("../../public/images/volume-on.svg") no-repeat
                    50% 50%;
                mask: url("../../public/images/volume-on.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                width: 20px;
                height: 19px;
                background-color: var(--isp-color-school);
                display: inline-block;
                transition: 0.3s;
            }
            &.volume-off {
                span:after {
                    content: "";
                    -webkit-mask: url("../../public/images/volume-off.svg")
                        no-repeat 50% 50%;
                    mask: url("../../public/images/volume-off.svg") no-repeat
                        50% 50%;
                    -webkit-mask-size: cover;
                    mask-size: cover;
                    width: 20px;
                    height: 19px;
                    background-color: var(--isp-color-school);
                    display: inline-block;
                    transition: 0.3s;
                }
            }
        }
        .play-resume-button {
            width: 44px;
            height: 44px;
            background-color: white;
            border-radius: 50%;
            border: none;
            margin-right: 28px;
            // aspect-ratio: 1/1;
            // padding: unset !important;
            padding: 7px 17px;
            path {
                stroke: $color-school;
                fill: $color-school;
            }

            .video-play-icon {
                width: 14px;
                height: 13px;
            }

            .video-resume-icon {
                width: 11px;
                height: 18px;
            }
        }

        @include media-breakpoint-down(md) {
            width: 90% !important;
            bottom: 12px;
            left: 5%;
        }

        .video-progress {
            position: relative;
            width: 100%;
            height: 4px;
            background-color: #d9d9d9;

            @include media-breakpoint-down(md) {
                height: 1px;
            }

            .progress-bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            .progress-indicator {
                position: absolute;
                top: -14px;
                width: 32px;
                height: 32px;
                background-color: white;
                border-radius: 50%;

                @include media-breakpoint-down(md) {
                    width: 24px;
                    height: 24px;
                    top: -12px;
                }
            }
        }
    }
}

// ************************************** INIZIO HUBSPOT FORM *************************************
.hbspt-form {
    width: 100%;

    form {
        width: 100%;

        .hs-dateinput {
            input {
                background-image: url("../../public/images/calendar-black.svg");
                background-position: right 10px center;
                padding-right: 30px;
                box-shadow: none;
                background-repeat: no-repeat;
            }
        }
        .hs-field-desc {
            font-size: 14px;
            font-weight: 425;
            line-height: 14px;
            white-space: nowrap;
        }
        .form-columns-2 {
            display: flex;
            flex-direction: column;
            gap: 0 16px;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            .hs-form-field {
                width: 100% !important;
            }
        }

        .form-columns-3 {
            display: flex;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
            div {
                @include media-breakpoint-down(md) {
                    width: 100% !important;
                }
            }
        }

        .hs-form-field {
            max-width: 100% !important;
            margin-top: 24px;

            label {
                span {
                    font-size: 14px;
                    font-weight: 425;
                    line-height: 14px;
                    white-space: nowrap;
                }
            }

            .input {
                margin: 0 !important;

                textarea {
                    height: 190px !important;
                }

                .hs-input:not([type="checkbox"]):not([type="file"]):not(
                        [type="radio"]
                    ) {
                    width: 100% !important;
                    border: solid #c8cec9 1px;
                    border-radius: 4px;
                    min-height: 46px !important;
                    padding: 8px;

                    &:focus-visible {
                        outline: solid $form-focus 2px;
                        border: none;
                    }

                    &:hover {
                        background-color: $hover-form;
                    }
                }

                .hs-input[type="file"] {
                    background-color: transparent;
                    border: none;
                    margin-bottom: 24px !important;
                }

                .hs-input[type="radio"] {
                    width: 16px !important;
                    height: 16px !important;
                    accent-color: $checkbox-bg;
                    margin-right: 8px;
                }
            }
        }
    }

    .actions {
        display: flex;
        justify-content: center;

        .hs-button {
            background-color: $primary;
            border: none;
            border-radius: 32px;
            padding: 14px 24px;
            line-height: 16px;
            margin-top: 48px;

            &:hover {
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2) 0%,
                        rgba(0, 0, 0, 0.2) 100%
                    ),
                    var(--btn-primary-hover, var(--bs-primary));
                border-color: $primary;
            }

            &:disabled {
                background: $body-tertiary-bg;
            }
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url("../../public/images/arrow_select.svg");
        background-position: right 10px center;
        padding-right: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        box-shadow: none;
        background-repeat: no-repeat;
    }

    .hbspt-form select {
        box-shadow: none !important;
    }

    input:-webkit-autofill {
        background-color: $white;
    }

    input:-webkit-autofill:focus {
        background-color: $white;
    }

    .hs_child_name .hs-child_name .hs-fieldtype-text .field .hs-form-field {
        margin-top: 24px;
    }

    ul {
        padding-left: 0px;

        li {
            list-style: none;
            font-size: 14px;
            font-weight: 425;
            line-height: 14px;
            margin-top: 8px;

            .hs-error-msg,
            .hs-main-font-element {
                color: $alert-form;
                display: flex;

                &::before {
                    content: "";
                    display: flex;
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                    background-image: url("../../public/images/alert_form.svg");
                    object-fit: cover;
                }
            }
        }

        .hs-form-booleancheckbox {
            .hs-form-booleancheckbox-display {
                @include media-breakpoint-up(md) {
                    display: flex;
                    align-items: center;
                }

                .hs-input {
                    margin-right: 8px;
                    width: 18px !important;
                    height: 18px !important;
                    appearance: none;
                    background-color: $white;
                    border-radius: 2px;
                    border: 1px solid #c8cec9;

                    &:checked {
                        background-color: $checkbox-bg;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;

                        &::before {
                            content: "";
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-bottom: 2px solid $white;
                            border-right: 2px solid $white;
                            transform: rotate(45deg);
                            margin-bottom: 4px;
                        }
                    }
                }
                span {
                    margin-left: 8px;
                    white-space: unset;
                }
            }
        }
    }

    fieldset {
        max-width: unset !important;
    }

    .hs-datepicker {
        .pika-label {
            color: $color-school;
            font-size: 14px;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0.28px;
        }

        .is-selected {
            button {
                background-color: $color-school;
                border: none;
                box-shadow: none;
            }
        }

        .pika-prev {
            background-image: url("../../public/images/angle-right-black.svg") !important;
            color: $black !important;
            transform: rotate(180deg);
            opacity: 1;
            width: 16px;
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 14px;
        }

        .pika-next {
            background-image: url("../../public/images/angle-right-black.svg") !important;
            color: $black !important;
            opacity: 1;
            width: 16px;
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: 14px;
        }
    }

    .fn-date-picker {
        .pika-day {
            background-color: $white;
            width: 41px;
            height: 32px;
            color: $black;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: $color-school;
            }
        }

        .pika-table {
            margin-top: 16px;
        }

        .pika-lendar {
            width: auto;
        }
    }

    .fn-date-picker .pika-table th {
        color: $black;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        text-decoration: none !important;

        &:hover {
            cursor: pointer;
        }
    }

    .fn-date-picker .pika-button:hover {
        &:hover {
            background: $color-school !important;
        }
    }

    .fn-date-picker .is-today .pika-button {
        color: $color-school !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        &:hover {
            color: $white !important;
        }
    }

    .fn-date-picker.pika-single.is-bound {
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    }

    .fn-date-picker .pika-table abbr {
        cursor: auto !important;
    }

    .hs-form-radio {
        label {
            display: flex !important;
            align-items: center;
        }
    }

    input[type="file"]::file-selector-button {
        background-color: $color-school;
        border-radius: 8px;
        border: solid $color-school 1px;
        padding: 12px 16px;
        line-height: 16px;
        color: $white;
    }

    .select2-container {
        width: 100% !important;
        height: 46px;
    }

    .select2-container--default .select2-selection--single {
        height: 100%;
        display: flex;
        align-items: center;

        .select2-selection__arrow {
            height: 100%;

            b {
                display: none !important;
            }
        }

        span[role="presentation"] {
            margin-right: 16px;
            background-image: url("../../public/images/arrow_down_black.svg");
            background-repeat: no-repeat;
            background-size: 12px 7px;
            background-position: center center;

            // [dir="rtl"] & {
            //     background-position: left !important;
            //     width: 100%;
            // }
        }
    }

    select.hs-input {
        display: none !important;
    }
}

.select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background-color: $color-school-light !important;
    color: $color-school !important;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: $color-school-light !important;
}

// ************************************** FINE HUBSPOT FORM **************************************

// ** Start Privacy Settings Button **//
.eNuMKj {
    bottom: 90px;
    left: 30px !important;

    @include media-breakpoint-down(xl) {
        left: 19px !important;
        bottom: 80px;
        z-index: 1029 !important;
    }
}

.kcnOUH {
    right: 40px;
    left: unset;
    bottom: 30px;
    @include media-breakpoint-down(xl) {
        left: 19px !important;
        z-index: 1029 !important;
    }
}
// ** End Privacy Settings Button **//

// ** Start ChatBot **//
#kenytChatBubble.style1 {
    // left: 15px !important;
    bottom: 90px !important;

    @include media-breakpoint-down(xl) {
        bottom: 80px !important;
        z-index: 1029 !important;
    }
}

// #kenytChatBubble.style1.position-left {
//     margin-left: 0px !important;
// }
// ** End Chatbot **//
