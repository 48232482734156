.listing-events-widget{
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    min-height: calc(var(--vh, 1vh)* 100 - 560px);
    @include media-breakpoint-down(md) {
        min-height: calc(var(--vh, 1vh) * 100 - 505px);
    }
    .row-event{
        transition: .3s;
        &:hover{
        transform: scale(0.98);
        }
        .container{
            @include media-breakpoint-down(md) {
                padding: 0 16px;
            }
        }
    }
    .listing-events-filters{
        margin-bottom: 56px;
        @include media-breakpoint-down(md) {
            overflow-x: scroll;
            white-space: nowrap;
            width: 100%;
            padding: 0 8px;
            margin-bottom: 48px;
        &::-webkit-scrollbar {
            display: none; 
        }
        }
        .btn-listing-filter{
        border: 1px solid $color-school;
        color:black;
        border-radius: 8px;
        padding: 12px 16px;
        margin: 0 8px;
        background-color: $white;
        
        &:hover {
            border: 1px solid $color-school;
            background-color: $color-school-light;
        }
        &.filter-active{
            background-color: $color-school;
            color: $white;
        }
        }
    }
    .button-load-more {
        padding-top: 72px;
        @include media-breakpoint-down(md) {
            padding-top: 48px;
        }
    }

    .listng-events-loader-wrapper {
        height: 100%;
        width: 100%;
        min-height: calc(var(--vh, 1vh)* 100 - 560px);
        @include media-breakpoint-down(md) {
            min-height: calc(var(--vh, 1vh) * 100 - 505px);
        }
        .spinner-border {
            color: $color-school;
        }
    }

}

