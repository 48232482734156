.opportunities-widget{
    background-color: $color-school-light;
    .opportunities-intro{
        margin-bottom: 32px;
        .opportunities-title{
            color: $color-school;
        }
        .opportunities-description{
            font-size: 20px;
            line-height: 32px
        }
    }
    .opportunities-container{
        .opportunities-card{
            border-radius: 8px;
            border: unset;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            transition: .3s;
            overflow: hidden;
            &:hover{
                box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
                .opportunity-image{
                    transition: .3s;
                    img{
                        transform: scale(1.1);
                    }
                }
            }
            .opportunity-image{
                overflow: hidden;
                position: relative;
                z-index: 1;
                img{
                    transition: .3s;
                    width: 100%;
                    aspect-ratio: 47/24;
                    height: auto;
                    object-fit: cover;
                    border-radius: 8px 8px 0 0;
                }
                .age-range{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-color: $color-school;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    z-index: 2;
                    p{
                        font-size: 16px;
                        line-height: 16px;
                        color: $white;
                        text-align: center;
                        font-weight: 400;
                        margin-bottom: 0;
                    }
                }
                .small-circle{
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    background-color: $accent;
                    right: 88px;
                    top:72px;
                    border-radius: 50%;
                    z-index: 2;
                }
            }
        }
        .opportunity-title{
            padding: 12px;
            .buttons-ilos-container{
                gap: 16px;
                button{
                    border: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background: $color-school-light;
                    svg{
                        path{
                            fill: $color-school;
                            stroke: $color-school;
                        }
                    }
                }
            }
        }
    }
} 
.modal-opportunity{
    background-color: rgba(0, 0, 0, .7);
    .modal-dialog {
        --bs-modal-width: 984px;
    }
    .close-modal-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 2;
    }
    .modal-title{
        color: $color-school;
    }
    .modal-body{
        max-height: 689px;
        @include media-breakpoint-down(md) {
            max-height: unset;
        }
        .first-column-modal{
            padding: 48px 24px 48px 48px;
            background-color: #fff;
            //height: 612px;
            @include media-breakpoint-down(md) {
                height: auto;
                padding: 48px 16px 0px 16px;
            }
            .content-description{
                padding-bottom: 8px;
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    @include media-breakpoint-down(md) {
                        font-size: 15px;
                        line-height: 19px;
                    }
                }
                a {
                    color: $color-school;
                }
                ul {
                    list-style: none;
                    padding: unset;
                    margin: unset;
                    margin-bottom: 8px;
                        li{
                            padding: 0 4px;
                            display: flex;
                            align-items: start;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            gap:8px;
                            @include media-breakpoint-down(md) {
                                font-size: 15px;
                                line-height: 19px;
                            }
                             &::before{
                                content: '';
                                width: 10px;
                                height: 10px;
                                margin-top: 4px;
                                background-color: $accent;
                                border-radius: 50%; 
                                flex-shrink: 0;
                             }
                    }
                }
            }
            .opportunity-partners{
                border-top: 1px solid rgba(237, 237, 237, 1);
                padding: 16px 0 48px 0;
                @include media-breakpoint-down(md) {
                    padding-bottom: 16px;
                }
                img{
                    height: 30px;
                    width: auto;
                    margin-right: 24px;
                    object-fit: contain;
                    margin-bottom: 8px;
                }
            }
        }
        .second-column-modal{
            background-color: $accent-light;
            padding: 48px 48px 48px 24px;
            //height: 612px;
            @include media-breakpoint-down(md) {
                height: auto;
                padding: 24px 16px 48px 16px;
            }
            .title-competencies{
                margin-top: 58px;
                margin-bottom: 16px;
                @include media-breakpoint-down(md) {
                    margin-top: 0px;
                }
            }
            .competencies-circle{
                .percent-container{
                    margin-bottom: 16px;
                    &:nth-child(1){
                        .percent-circle {
                        background: conic-gradient(
                            #294DFF calc(var(--percent) * 1%),
                            #EBEEEB 0
                        );
                        }
                    }
                     &:nth-child(2){
                        .percent-circle {
                        background: conic-gradient(
                            #018534 calc(var(--percent) * 1%),
                            #EBEEEB 0
                        );
                        }
                    }
                    &:nth-child(3){
                        .percent-circle {
                        background: conic-gradient(
                            #EB7007 calc(var(--percent) * 1%),
                            #EBEEEB 0
                        );
                        }
                    }
                    &:nth-child(4){
                        .percent-circle {
                        background: conic-gradient(
                            #24118E calc(var(--percent) * 1%),
                            #EBEEEB 0
                        );
                        }
                    }
                       &:nth-child(5){
                        .percent-circle {
                        background: conic-gradient(
                            #E80E57 calc(var(--percent) * 1%),
                            #EBEEEB 0
                        );
                        }
                    }
                }
                .percent-text{
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    margin-left: 8px;
                    text-transform: capitalize;
                }
                .percent-circle {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }
                
                .percent-circle::before {
                    content: "";
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: $accent-light;
                    position: absolute;
                }
                
                .percent-circle[data-percent] {
                    --percent: attr(data-percent);
                }
            }
            .opportunity-quote{
                .quote-image{
                    margin: 32px 0 16px 0;
                    svg{
                        path{
                            fill: $accent;
                        }
                    }
                }
                .quote-text{
                    font-size: 16px;
                    font-style: italic;
                    font-weight: 700;
                    line-height: 24px;
                    color: $color-school;
                    margin-bottom: 8px;
                }
                .quote-info{
                        font-style: normal;
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        @include media-breakpoint-down(md) {
                            font-size: 15px;
                            line-height: 19px;
                        }
                }
            }
        }
    }
}