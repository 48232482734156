.overview-tabs-widget{
    position: relative;
    padding-right:0 !important;
    padding-left: 0 !important;
    .intro-overview-tabs{
        margin: 0 auto 32px auto ;
        .title-tabs{
            .h2{
                color: $color-school;
            }
        }
        .description-tabs{
            p, li{
                font-size: 20px;
                line-height: 32px;
            }
        }
        .category-tabs{
            background-color: $color-school;
            color: $white;
            padding: 4px 8px;
            border-radius: 8px;
            margin-bottom: 24px;
            span{
                font-size:12px;
                line-height: 12px;
                }
        }
    }
    .col-tabs-image{
        position: relative;
        aspect-ratio: 1/1;
        padding: 0;
        picture{
            display: flex;
            align-items: center;
            width: 100%;
            aspect-ratio: 1/1;
            position: absolute;
            top:0;
        }
        img{
          
            width:100%;
            aspect-ratio: 1/1;
            height: auto;
            object-fit: cover;
            border-radius: 0 8px 8px 0;
            opacity: 0;
            transition: .5s;
            @include media-breakpoint-down(xl) {
                border-radius: 8px;
            }
        }
        @include media-breakpoint-down(xl) {
            margin-bottom: 48px;
            padding: 0 16px;
        }
        .show-image{
                opacity: 1;
        }
        &.ratio-16-9{
            aspect-ratio: 16/9;
            picture{
                aspect-ratio: 16/9; 
            }
            img{
                aspect-ratio: 16/9;
            }
        }
        &.ratio-4-3{
            aspect-ratio: 4/3;
            picture{
                aspect-ratio: 4/3; 
            }
            img{
                aspect-ratio: 4/3;
            }
        }
    }
    .flex-lg-row-reverse{
        .col-tabs-image{
            position: relative;
            aspect-ratio: 1/1;
            picture{
                display: flex;
                align-items: center;
                display: block;
                width: 100%;
                aspect-ratio: 1/1;
                position: absolute;
                top:0;
            }
            img{
                width:100%;
                aspect-ratio: 1/1;
                height: auto;
                object-fit: cover;
                border-radius: 8px 0 0 8px;
                opacity: 0;
                transition: .5s;
                @include media-breakpoint-down(xl) {
                    border-radius: 8px;
                }
            }
            @include media-breakpoint-down(xl) {
                margin-bottom: 48px;
                padding: 0 16px;
            }
            .show-image{
                opacity: 1;
        }
        &.ratio-16-9{
            aspect-ratio: 16/9;
            picture{
                aspect-ratio: 16/9; 
            }
            img{
                aspect-ratio: 16/9;
            }
        }
        &.ratio-4-3{
            aspect-ratio: 4/3;
            picture{
                aspect-ratio: 4/3; 
            }
            img{
                aspect-ratio: 4/3;
            }
        }
        }

    }
        .container-tabs-content{
            height: 100%;
            top:0;
            padding-right: 100px;
            @include media-breakpoint-down(xxl) {
                padding-right: 50px;
                padding-left: 70px;
            }
            @include media-breakpoint-down(xl) {
                position: relative;
                padding: 0 16px;
            }
        .flex-lg-row-reverse{
            .column-content{
                @include media-breakpoint-down(xl) {
                    padding-right: 0px;
                }
            }  
        }  .flex-lg-row{
            .column-content{
                @include media-breakpoint-down(xl) {
                    padding-left: 0px;
                }
            }  
        }
            img{
                path{
                    fill: $color-school;
                }
            }
            .column-content{
                .category-tabs{
                    background-color: $color-school;
                    padding: 6px 10px;
                    border-radius: 4px;
                    span{
                        color: $white;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px
                    }
                }
                .title-tabs{
                    .tabs-title-tag{
                        color: $color-school;
                        font-size: 48px;
                        font-weight: 700;
                        line-height: 60px;
                        margin-top: 24px;
                    }
                }
                .description-tabs{
                    margin-top: 8px;
                    p, li{
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 32px;
                        margin-bottom: 0;
                    }
                    margin-bottom: 80px;
                }
                .tabs-container{
                    width: 100%;
                    .single-tab{
                        height: 130px;
                        flex-direction: column;
                        position: relative;
                        border-top: 1px solid $accent;
                        cursor: pointer;
                        border-left: 2px solid transparent;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        &:last-child{
                            border-bottom: 1px solid $accent;
                        }
                        &.hover-class{
                            min-height: 170px;
                            height: unset;
                            .tab-description{
                                a.btn-primary{
                                    color: #000;
                                }
                                p, ul, ol,a{
                                    opacity: 1;
                                    height: auto;
                                }
                            }
                            .tab-title{
                                transform: translateY(0);
                                transition: all .5s;
                                .tab-title-wrapper {
                                    padding-bottom: 8px;
                                    svg{
                                        path{
                                            fill: $accent;
                                        }
                                    }
                                }
                            }
                            
                        }
                    .tab-title{
                        transform: translateY(80%);
                        .tab-title-wrapper{
                            color: $color-school;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 32px;
                            margin-bottom: 0px;
                            transition: .5s;
                            .tab-title-text {
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                line-clamp: 1;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                margin-bottom: 0;
                            }
                            svg{
                                margin-right: 16px;
                                &.flip-arrow{
                                    transform: scaleX(-1);
                                }
                                path{
                                    fill: $color-school
                                }
                            }
                        }
                    }
                    .tab-description{
                        p,ul, ol,a{
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px; 
                            margin-bottom: 0;         
                            opacity: 0;
                            margin-left: 48px;
                            height: 0;
                        }
                        p a{
                            margin-left: 0;
                        }
                        li{
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px; 
                        }
                        ul, ol{
                            margin-left: 24px;
                        }
                        a {
                            color: $color-school;
                        }
                    }
                    }
                }
                .all-opportunities-cta{
                    margin-top: 40px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 56px;
                    } 
                        a{
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 0.32px;
                        color: $color-school;
                        display: flex;
                        align-items: center;
                        &::after{
                            content: '';
                            -webkit-mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                            mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                            -webkit-mask-size: cover;
                            mask-size: cover;
                            width: 7px;
                            height: 12px;
                            background-color: $color-school;
                            display: inline-block;
                            margin-left: 10px;
                            padding-top: 4px;
                            transition: .3s;
                         }
                         &:hover{
                            &::after{
                                transform: translateX(8px);
                             }
                         }
                        }
                }
            }
        }
}

.overview-tabs-widget-mobile{
   .overview-tabs-info-mobile{
    padding-right: 16px;
    padding-left: 16px;
    .category-tabs{
        background-color: $color-school;
        padding: 6px 10px;
        border-radius: 4px;
        margin-bottom: 32px;
        span{
            color: $white;
            font-size: 12px;
            line-height: 18px; 
        }
    }
    .title-tabs{
        .tabs-title-tag{
            color: $color-school;
            font-size: 36px;
            font-weight: 700;
            line-height: 42px;
        }
    }
    .description-tabs{
        margin-top: 8px;
        p, li{
            font-size: 18px;
            font-weight: 400;
            line-height: 30px; 
        }
    }
   } 
   .overview-tabs-carousel{
    width: 100%;
    .swiper-wrapper{
        .tab-slider{
            width: 325px !important;
            margin-right: 24px;
            &:last-child{
                margin-right: 0;
            }
            .tab-img-slider{
                width: 325px;
                picture{
                    display: block;
                    img{
                  width: 100%;
                  height: 353px;
                  object-fit: cover;
                  border-radius: 8px;
                    }
                }
                margin-bottom: 24px;
            }
            .tab-title-slider{
                p{
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0.52px;
                    margin-bottom: 8px;
                    color: $color-school;
                    text-align: center;
                }
            }
            .tab-description-slider{
                p{
                font-size: 18px;
                font-weight: 400;
                line-height: 30px; 
                margin-bottom: 0;
                text-align: center;
                }
                li{
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px; 
                    margin-bottom: 0;
                }

            }
        }
    }
   } 
   .swiper-pagination-tabs{
    gap: 5px;
    margin-top: 24px;
   .swiper-pagination-bullet{
    height: 10px;
    width: 10px;
    background-color: $primary;
    border-radius: 8px;
    &.swiper-pagination-bullet-active{
        width: 40px;
        background-color: $color-school;
    }
   } 

}
}