.outline-card-scroll-widget {
    position: relative;
    .outline-card-scroll-content {
        .outline-card-title {
            font-size: 32px;
            font-weight: 700;
            line-height: 42px;
            margin-bottom: 32px;
            @include media-breakpoint-down(md) {
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 24px;
            }
        }
    }
    .outline-card-carousel {
        position: relative;
        @media (max-width: 1600px) {
            width: 1140px;
        }
        @include media-breakpoint-down(xxl) {
            width: unset;
        }
        @include media-breakpoint-down(md) {
            padding: 0 8px;
        }
        .outline-card-slide {
            gap: 24px;
            border: 1px solid $primary;
            border-radius: 8px;
            height: auto !important;
            .outline-card-icon {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            img {
                width: 75px;
                height: 75px;
                object-fit: contain;
            }
            .outline-card-text {
                .outline-card-title {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0.48px;
                    color: $color-school;
                    margin-bottom: 8px;
                    @include media-breakpoint-down(md) {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
                .outline-card-description img {
                    width: unset;
                    height: auto;
                    max-width: 100%;
                }
                .outline-card-description p {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 0;
                    @include media-breakpoint-down(md) {
                        font-size: 15px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
    .swiper-button-disabled {
        svg {
            opacity: 0.4;
        }
    }
    .swiper-button-prev {
        cursor: pointer;
        position: absolute;
        bottom: calc(50% - 50px + 22px);
        left: 64px;
        cursor: pointer;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        z-index: 1;
        circle {
            stroke: $color-school;
        }
        path {
            fill: $color-school;
        }
        @media (max-width: 1440px) {
            left: 16px;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
        &.button-gallery-flip {
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        position: absolute;
        cursor: pointer;
        right: 64px;
        bottom: calc(50% - 50px + 22px);
        transform: rotate(180deg);
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle {
            stroke: $color-school;
        }
        path {
            fill: $color-school;
        }
        &.button-gallery-flip {
            transform: rotate(360deg);
        }
        @media (max-width: 1440px) {
            right: 16px;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    .swiper-pagination {
        gap: 5px;
        .swiper-pagination-bullet {
            height: 10px;
            width: 10px;
            background-color: $primary;
            border-radius: 8px;
            &.swiper-pagination-bullet-active {
                width: 40px;
                background-color: $color-school;
            }
        }
    }
    .footer-slider {
        margin-top: 32px;
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
        .cta-container {
            // height: 24px;
            a {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.32px;
                color: $color-school;
            }
        }
    }
}
