.people-card-widget{
    .people-card-title-tag{
        color: $color-school;
        text-align: center;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
            text-align: left;
        }
    } 
    .people-card{
        padding-right: 8px;
        padding-left: 8px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        --bs-card-border-color: unset !important;
        overflow: auto;
        padding: 24px 32px 16px 32px;
        transition: .4s;
        @include media-breakpoint-down(md) {
            padding: 24px 8px 16px 8px;
        }
        .people-card-image{
            overflow: hidden;
            width: 100%;
            aspect-ratio: 1/1;
            height: auto;
            border-radius: 50%;
            border: unset;
            margin-bottom: 16px;
            img{
                object-fit:cover ;
                transition: .4s;
                width: 100%;
                height: auto;
                aspect-ratio: 1/1;
            }
        }
        .cta-container{
            padding: 0 0 16px 0;
            &.card-footer{
                background-color: unset;
            }
            .stretched-link::after{
                position: relative;
            } 
            .btn-only-arrow:after {
                transform: translateX(10px);
                margin-left: 0;
            }
            .btn-only-arrow:hover:after {
                transform: translateX(16px);
                margin-left: 0;
            }
            .button-arrow-flip{
                &::after{
                    transform: rotate(180deg) translateX(-10px); 
                }
            }
                .button-arrow-flip:hover{
                    &::after{
                        transform: rotate(180deg) translateX(-16px); 
                    }
            }
        }
        .card-body{
            position: relative;
            padding: 0;
            @include media-breakpoint-down(md) {
                padding: 0 8px 24px 8px;
            }
            .circle-card{
                width: 78px;
                height: 78px;
                background-color: $color-school-light;
                border-radius: 50%;
                position: absolute;
                left: -39px;
                top: -65px;
                @include media-breakpoint-down(md) {
                    width: 39px;
                    height: 39px;
                    left: -20px;
                    top: -29px;
                }
            }
            .card-name{
                color: $color-school;
                margin-bottom: 8px;
                position: relative;
                z-index: 2;
                text-align: center;
            }
            .people-job{
                color: $color-school;
                margin-bottom: 0;   
            }
            a{
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.32px;
                color: $color-school;
                display: flex;
                align-items: center;
                &::after{
                    content: '';
                    -webkit-mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                    mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                    -webkit-mask-size: cover;
                    mask-size: cover;
                    width: 7px;
                    height: 12px;
                    background-color: $color-school;
                    display: inline-block;
                    margin-left: 10px;
                    padding-top: 4px;
                    transition: .3s;
                    position: relative;
                    z-index: 1;
                 }
                 &:hover{
                    &::after{
                        transform: translateX(8px) !important;
                     }
                 }
              
                &.button-outline-flip{
                    &::after{
                    transform: rotate(180deg);
                    }
                    &:hover{
                        &::after{
                            transform: rotate(180deg) translateX(-8px) !important;
                         }
                     }
                    }
                 }
        }
        &:hover{
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
            .people-card-image{
                img{
                    transform: scale(1.1);
                }
            }
        }
    }
}
.modal-quote-people-card{
    max-height: 425px;
    overflow-y: auto;
    @include media-breakpoint-down(lg) {
        max-height: unset;
    }
}