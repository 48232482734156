.widget-wheel {
    .skill-title {
        @include media-breakpoint-down(lg) {
            text-align: center;
            padding: 0 60px;
            margin-top: -32px;
        }
    }
    .skill-description {
        @include media-breakpoint-down(lg) {
            text-align: center;
            padding: 0 60px;
        }
    }
    .skill-item {
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: 0.3s;
        .skill-item-title {
            font-size: 20px;
            font-weight: 700;
        }
    }
    .circle-wrapper {
        position: relative;
        padding-left: 40px;
        .small-circle {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            bottom: 0;
            left: 0;
        }
    }
    .opacity-0 {
        opacity: 0;
    }
    .opacity-1 {
        opacity: 1;
    }
    circle {
        transition: stroke 0.3s ease, filter 0.3s ease; /* Transizione morbida per colore e ombra */
    }

    .circle-text-group {
        transition: transform 0.5s ease-in-out;
        transform: rotate(-55deg);
        transform-origin: center;
    }
    .circle-text-group text {
        opacity: 0.3; /* Opacità per le parole inattive */
        transition: opacity 0.3s ease, fill 0.3s ease; /* Transizione morbida */
        font-size: 32px;
        font-weight: 700;
    }
    .circle-center-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%; /* Assicura il pieno utilizzo dell'area */
        width: 100%; /* Assicura il pieno utilizzo dell'area */
        font-size: 16px;
        text-align: center;
    }
    /* Colori ciclici con nth-child */
    .circle-text-group text:nth-child(1) textPath {
        fill: $color-school; /* Primo colore */
    }

    .circle-text-group text:nth-child(2) textPath {
        fill: #7a56b8; /* Secondo colore */
    }

    .circle-text-group text:nth-child(3) textPath {
        fill: #324cf5;
    }

    .circle-text-group text:nth-child(4) textPath {
        fill: #cd2d51;
    }

    .circle-text-group text:nth-child(5) textPath {
        fill: #8a6e00;
    }

    .circle-text-group text:nth-child(n + 6) textPath {
        fill: #00843d;
    }
    .circle-text-group text {
        cursor: pointer;
    }
    .circle-text-group text.active {
        opacity: 1;
    }
    .circle-container {
        position: relative;
        width: 520px;
        height: 520px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-svg {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .circle-center-text {
        z-index: 1;
    }

    .circle {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .wheel-button-container {
        @include media-breakpoint-down(lg) {
            order: -1;
            display: flex;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            margin-top: 0 !important;
        }
    }

    .wheel-button-prev {
        cursor: pointer;
        cursor: pointer;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        z-index: 1;
        circle {
            stroke: $color-school;
        }
        path {
            fill: $color-school;
        }
        @media (max-width: 1440px) {
            left: 16px;
        }
        &.button-gallery-flip {
            transform: rotate(180deg);
        }
    }
    .wheel-button-next {
        cursor: pointer;
        transform: rotate(180deg);
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle {
            stroke: $color-school;
        }
        path {
            fill: $color-school;
        }
        &.button-gallery-flip {
            transform: rotate(360deg);
        }
        @media (max-width: 1440px) {
            right: 16px;
        }
    }

    .circle-container {
        position: relative;
        width: 500px;
        height: 500px;
        margin: 0 auto;
    }

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        position: relative;
    }

    .word {
        position: absolute;
        transform-origin: center;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: bold;
    }
}
