.dual-curriculum-widget {
    .chessboard-image-container 
    
    {
        img{
            aspect-ratio: 2/3;
            height: 100%;
            @include media-breakpoint-down(lg) {
                aspect-ratio: 1/1;
            }
        }

    }
  
}