.enquire-now-widget {
    position: relative;
    @include media-breakpoint-down(lg) {
       padding-right: 0 !important;
       padding-left: 0 !important;
     }
    .enquire-now-content {
       padding-top: 140px;
       padding-bottom: 272px;
       position: relative;
        @include media-breakpoint-down(lg) {
            padding-bottom: 0;
            padding-top: 54px;
        }
        &.enquire-now-image-flip{
            clip-path: circle(49.3% at 88% 50%); 
            @include media-breakpoint-down(md) {
                clip-path: none;
              }
        }
      .enquire-now-title{
          font-weight: bold;
          font-size: 3rem;
          line-height: 60px;
      
          @include media-breakpoint-down(lg) {
              font-weight: bold;
              font-size: 2.25rem;
              line-height: 42px;
          }
      }
       .enquire-description{
        p{
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 0;
            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 30px;
              }
        }
       }
       .cta-container{
        margin-top:48px;
        gap: 16px;
        a{
            position: relative;
            z-index: 1;
        }
        @include media-breakpoint-down(lg) {
           margin-top: 32px;
           margin-bottom: 16px;
         }
       }
       .semircircle-image{
        position: absolute;
        bottom: 0;
       }
    }
    .enquire-now-image {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        &.diagonal-mask {
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            // &.enquire-now-image-flip{
            //     clip-path: polygon(0 0, 0% 100%, 100% 100%);
            // }
            @include media-breakpoint-down(lg) {
            position: relative;
            clip-path: polygon(0 75%, 100% 0, 100% 100%, 0 100%);
            &.enquire-now-image-flip {
                    clip-path: polygon(0 0, 100% 75%, 100% 100%, 0% 100%);
                }
            }
            &.enquire-now-image-flip{
                clip-path: polygon(0 0, 0% 100%, 100% 100%);
            }
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &.semicircle-mask {
            @include media-breakpoint-down(lg) {
                position: relative;
            }
            img {
                -webkit-mask-image: url("../../../public/images/mask-intro-banner-circle.png");
                mask-image: url("../../../public/images/mask-intro-banner-circle.png");
                -webkit-mask-position: 100%;
                mask-position: 100%;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 60% 100%;
                mask-size: 60% 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include media-breakpoint-down(lg) {
                    -webkit-mask-image: url("../../../public/images/mask-intro-banner-circle-mobile.png");
                    mask-image: url("../../../public/images/mask-intro-banner-circle-mobile.png");
                    -webkit-mask-position: 100% center;
                    mask-position: 100% center;
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                    mask-size: 100% 100%;
                    object-fit: cover;
                }
            }
            &.enquire-now-image-flip {
                transform: scaleX(-1);
            }
        }
    }
}