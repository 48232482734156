.pimcore_area_schools-card .modal-opportunity .modal-body .second-column-modal {
    background-color: $light-secondary !important;
}

.widget-school-cards{
    .schools-card-intro-text{
        h2{
            color: $color-school;
        }
        p{
            font-size: 20px;
            line-height: 32px;
        }
    }
    .school-card-wrapper{
        &.col-md-4{
            padding: 8px; 
            &:nth-child(3n + 1) {
                padding-left: 0; 
                @include media-breakpoint-down(md) {
                    padding-left: 8px;
                }
              }
              
            &:nth-child(3n) {
                padding-right: 0;
                @include media-breakpoint-down(md) {
                    padding-right: 8px;
                }
              }
          }
    }
    .school-card{
        border-radius: 8px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        height: 100%;
        @include media-breakpoint-down(md) {
            border: unset;
            background-color: $white;
        }

        .school-card-image{
            img{
               width: 90px;
               height: 90px;
               object-fit: cover;
               border-radius: 8px;
            }
            padding-right: 16px;
        }
        .school-card-title{
            margin-bottom: 16px;
            .h4{
                color: $color-school;
            }
            svg{
                flex-shrink: 0;
            }
            .school-card-location{
                    p{
                        margin-bottom: 0;
                        margin-left: 8px;
                    }
            }
        }
        .school-card-content{
            margin: 16px 0;
            .label{
                background-color: $light-secondary;
                padding: 4px 8px;
                border-radius: 4px;
                margin-right: 8px;
                margin-bottom:8px;
                width: fit-content;
                p{
                    font-size: 12px;
                    line-height: 12px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.modal-schools-card{
/* mobile */
@include media-breakpoint-down(md) {
    .btn-close{
        width: 24px;
        height: 24px;
    }
    .header-school-info-popup {
        padding: 16px 16px 0 16px;
        img{
            width: 94px;
            height: 94px;
            border-radius: 8px;
            object-fit: cover;
        }
        .school-info-popup-title{
           padding-left: 16px;
           .label{
            background-color: $light-secondary;
            padding: 4px;
            border-radius: 4px;
            margin-right: 8px;
            margin-bottom:8px;
            width: fit-content;
           }
           

        }
    }
    .body-school-info-popup{
        padding: 0 16px 16px 16px;
        .programs-school-info-popup{
            background-color: $light-secondary;
            border-radius: 8px;
            span p {
                display: inline;
              }
        }
    }
}

.widget-schoolCards-map{
    height: auto;
    aspect-ratio: 16/9;
    width: 100%;
    margin-bottom: 24px;
}
.competencies-circle{
    p{
        margin-bottom: 0;
        font-size: 20px;
        line-height: 32px;
    }
}
}

.modal-full-width-map{
    .btn-close {
        width: 72px !important;
        height: 72px !important;
        background-color: white;
        border: none; 
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; 
        opacity: 1;
        border-radius: 0;
        top: 0 !important;
        right: 0 !important
      }
      
      .btn-close::before,
      .btn-close::after {
        content: '';
        position: absolute;
        width: 24px; 
        height: 2px; 
        background-color: black;
        transform-origin: center;
      }
      
      .btn-close::before {
        transform: rotate(45deg);
      }
      
      .btn-close::after {
        transform: rotate(-45deg);
      }
    .modal-dialog{
        height: 100vh;
        --bs-modal-width:100% !important;
        .modal-content{
            height: 100%;
            border-radius: 0 !important;
        }
    }
    .modal-body{
        height: 100%;
        max-height: unset !important;
        .row{
            height: 100%;
        }
        .second-column-modal{
            padding: 0 !important;
        }
        .first-column-modal{
            height: 100vh;
            overflow-y: auto;
            padding: 48px 24px !important;
           .school-card{
            border-radius: 8px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            margin-bottom: 16px;
            cursor: pointer;
            &.focused{
                border: 1px solid $color-school;
                background: $color-school-light;
            }
            .header-card{
                img{
                    width: 96px;
                    height: 96px;
                    object-fit: cover;
                    border-radius: 8px;
                    margin-right: 16px;
                }
                .school-card-title{
                    .school-card-location{
                        svg{
                            width: 22px;
                            height: 26px;
                            flex-shrink: 0;
                        }
                    }
                    h4{
                        color: $color-school;
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            }
           } 
        }
        .school-info-popup{
            left: 25%;
            z-index: 1;
            padding: 40px 24px;
            height: 100%;
         
            .button-close-popup{
                position: absolute;
                top: 16px;
                right: 16px;
                cursor: pointer;
            }
            .school-info-popup-content{
                background-color: $white;
                position: relative;
                overflow-y: auto;
                height: 100%;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            .header-school-info-popup {
                img{
                    width: 140px;
                    height: 140px;
                    object-fit: cover;
                    border-radius: 8px;
                    margin-right: 16px;
                }
                .school-info-popup-title{
                   .label{
                    background-color: $light-secondary;
                    border-radius: 8px;
                    width: fit-content;
                    p{
                        font-size: 12px;
                        line-height: 12px;
                        margin-bottom: 0;
                    }
                   }
                    .h4{
                        color: $color-school;
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            
            }
        } 
        .programs-school-info-popup{
            background-color: $light-secondary;
            border-radius: 8px;
            span p {
                display: inline;
              }
        }
        }
    }
    .full-map-container{
    height: 100%;
    width: 100%;
    }
}
.custom-tooltip{
    padding: 8px;
    border-radius: 8px;
    height: unset;
    position: absolute;
    background-color: $white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    padding: 8 16px;
    max-width: 140px;
    height: auto;
    color: $color-school;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0;
    padding: 8px;
}