.widget-accordion-wrapper {
position: relative;
z-index: 1;
    .widget-accordion {
        width: 952px;

        .accordion-item {
            border: none !important;
            margin-bottom: 14px;
            .accordion-header {
                min-height: 54px;
                display: flex;

                .accordion-button {
                    background-color: $white;
                    border: solid $primary 1px;
                    border-radius: 8px;

                    font-size: 18px;
                    font-weight: 700;
                    line-height: 30px;
                    color: $color-school;
                    transition: .4s;
                    &:hover{
                        transform: scale(.98);
                    }

                    &[aria-expanded=false]{
                        .accordion-minus-icon {
                            display: none;
                        }
                        .accordion-plus-icon {
                            display: block;
                        }
                    }

                    &[aria-expanded=true]{
                        .accordion-minus-icon {
                            display: block;
                        }
                        .accordion-plus-icon {
                            display: none;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    &:focus:not([aria-expanded=true]) {
                        box-shadow: 0 0 0 0.3rem $light-secondary;           
                    }

                    &::after {
                        display: none;
                    }
                
                    svg path {
                        fill: $color-school;
                    }
                }
            }

            .accordion-body {
                padding: 12px !important;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                img{
                    max-width: 100%;
                }
                a {
                    color: $color-school;
                }
            }

        }
    }
}

