.map-buttons-wrapper{
    @include media-breakpoint-down(md) {
    .btn-primary{
        margin-top: 16px;
    } 
}
}
.widget-map-event{
    @include media-breakpoint-down(md) {
       padding-left: 0;
       padding-right: 0;
    }  
}
.overview-countdown-widget{
    position: relative;
.widget-accordion-wrapper{
    padding-left: 0;
    padding-right: 0;
}
}
.intro-event-layout {
    h3{
        color: $color-school;
    }
    .intro-paragraph{
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    margin-bottom: 32px;
    @include media-breakpoint-down(md) {
        margin-bottom: 24px;
    }
}
.countdown-layout-event{
        position: relative;
        .column-countdown-content{
            position: sticky;
            top: 166px;
        }
    .col-event-image{
        @include media-breakpoint-down(lg) {
        margin-top: 56px;
        }
    }
    .container-countdown-content{
        position: sticky;
        top: 166px;
        @include media-breakpoint-down(lg) {
            position: relative;
            top: unset;
        }
    }
.event-info{
    @include media-breakpoint-down(md) {
        margin-top: 16px;
    }
    .event-row-info{
        border-bottom: 1px solid $accent;
        padding: 24px 0;
        @include media-breakpoint-down(md) {
            padding: 16px 0;
        }
        p{
            margin-bottom: 0;
        }
        .event-text-info{
            p{
                font-size: 20px;
                line-height: 32px;  
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    line-height: 24px;
                }
                &:first-child{
                    text-transform: uppercase;
                    font-weight: 700;
                    color: $color-school;                
                }
            }
        }
        &.event{
            &::before {
                content: "";
                -webkit-mask: url("../../../public/images/event-icon.svg") no-repeat 50% 50%;
                mask: url("../../../public/images/event-icon.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: contain;
                width: 40px;
                height: 40px;
                background-color: var(--isp-color-school);
                display: flex;
                align-items: center;
                margin-right: 24px;
                padding-top: 4px;
                @include media-breakpoint-down(md) {
                    margin-right: 12px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        &.date{
            &::before {
                content: "";
                -webkit-mask: url("../../../public/images/calendar.svg") no-repeat 50% 50%;
                mask: url("../../../public/images/calendar.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: contain;
                width: 40px;
                height: 40px;
                background-color: var(--isp-color-school);
                display: flex;
                align-items: center;
                margin-right: 24px;
                padding-top: 4px;
                @include media-breakpoint-down(md) {
                    margin-right: 12px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        &.time{
            &::before {
                content: "";
                -webkit-mask: url("../../../public/images/time.svg") no-repeat 50% 50%;
                mask: url("../../../public/images/time.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: contain;
                width: 40px;
                height: 40px;
                background-color: var(--isp-color-school);
                display: flex;
                align-items: center;
                margin-right: 24px;
                padding-top: 4px;
                @include media-breakpoint-down(md) {
                    margin-right: 12px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        &.location{
            &::before {
                content: "";
                -webkit-mask: url("../../../public/images/marker.svg") no-repeat 50% 50%;
                mask: url("../../../public/images/marker.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: contain;
                width: 40px;
                height: 40px;
                background-color: var(--isp-color-school);
                display: flex;
                align-items: center;
                margin-right: 24px;
                padding-top: 4px;
                @include media-breakpoint-down(md) {
                    margin-right: 12px;
                    width: 24px;
                    height: 24px;
                }
            }
            .btn-arrow {
                margin-top: 12px;
                cursor: pointer;
            }
        }
    }
}
.widget-accordion-wrapper{
    margin-top: 104px;
    @include media-breakpoint-down(md) {
       margin-top: 48px;
    }
}
}