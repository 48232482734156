.latest-news-title {
    color: $color-school;
}

.latest-news-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.latest-news-card {
    max-width: 468px;
    // height: 502px;
    padding-bottom: 40px;
    background-color: $white;
    border-radius: 8px;
    cursor: pointer;
    transition: .4s;
    &:hover{
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
        .latest-news-card-img {
            img{
                transform: scale(1.1);
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding-bottom: 16px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 20px 0px;
        // height: 170px;
    }

    .latest-news-card-img {
        width: 100%;
        height: 229px;
        border-radius: 8px;

        @include media-breakpoint-down(md) {
            max-width: 120px;
            height: auto;
            margin-right: 12px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .4s;
        }

        .label-tag-wrapper {
            top: 0.5rem;
            right: 0.5rem;
        }
    }

    .card-body {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 24px;
    }

    .latest-news-ctegory-cta {
        top: 1rem;
        right: 1rem;
        background-color: $color-school;
        border-radius: 4px;
        padding: 10px;
        color: $white;
    }

    .latest-news-card-date {
        @include media-breakpoint-down(md) {
            padding-top: 0 !important ;
        }
    }

    .latest-news-card-title {
        color: $color-school;
        margin-top: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow : hidden;

        @include media-breakpoint-down(md) {
            margin-top: 8px;
        }
    }

    .latest-news-card-paragraph {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow : hidden;
    }

    .latest-news-details-link {
        color: $color-school;
        margin-top: 24px;
        font-size: 1rem;
        font-weight: 700;
        line-height: 16px;

        a.btn-arrow {
            svg {
                fill: $color-school;
                margin-left: 10px;
                transition: 0.3s;
            }
            &:hover {
                svg {
                    margin-left: 16px;
                }
            }
        }
    }
}