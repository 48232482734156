.kpi-widget-bubbles {
    @include media-breakpoint-down(md) {
        &.container-fluid {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .kpi-intro-container {
        margin-bottom: 56px;
        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }
        .editorial-title {
            .kpi-title-tag {
                color: $color-school;
                font-size: 48px;
                font-weight: 700;
                line-height: 60px;
                margin-bottom: 8px;
                text-align: center;
                @include media-breakpoint-down(md) {
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0.52px;
                }
            }
        }
        .kpi-description {
            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                margin-bottom: 0;
                text-align: center;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            a {
               color: $color-school; 
            }
        }
    }
    .row {
        width: 100%;
        flex-wrap: nowrap !important;
        overflow-x: auto;
        /* Nasconde la scrollbar orizzontale */
        scrollbar-width: none; /* Per Firefox */
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none; /* Nasconde la scrollbar per WebKit (Chrome, Safari) */
        }
    }
    .kpi-card-wrapper {
        &:not(:first-child), &:not(:last-child) {
            width: 266px;
            height: 266px;
            border-radius: 50%;
        }
        &:first-child, &:last-child {
            width: 266px;
             height: 266px;
             border-radius: 50%;
        }
          &:nth-child(even){
            margin-top: 50px;
        }
        @include media-breakpoint-down(md) {
            &:not(:first-child), &:not(:last-child) {
                width: 170px;
                height: 170px;
            }
            &:first-child, &:last-child {
                width: 170px;
                height: 170px;
            }
        }
        .kpi-card {
            padding: 24px 16px;
            border-radius: 50%;
            .kpi-icon{
                img, svg{
                    width: auto;
                    height: 80px;
                    object-fit: contain;
                    @include media-breakpoint-down(md) {
                        height: 60px;
                    }
                }
                margin-bottom: 8px;
            }
            .kpi-number p {
                font-size: 32px;
                font-weight: 700;
                line-height: 42px;
                margin-bottom: 0;
                color: $color-school;

                @include media-breakpoint-down(md) {
                    font-size: 26px;
                    line-height: 28px;
                    letter-spacing: 0.52px;
                }
            }


            .kpi-label p {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 0;
                a {
                    color: $color-school;
                }
            }
        }

        &:nth-child(1) {
            margin-left: 16px;
            padding-left: 0;
            .kpi-card {
                background-color: $light-secondary;
            }
        }
        &:nth-child(2) {
            .kpi-card {
                background-color: $accent-light;
            }
        }
        &:nth-child(3) {
            .kpi-card {
                background-color: $white;
            }
        }
        &:nth-child(4) {
            .kpi-card {
                background-color: $accent-light;
            }
        }
        &:last-child {
            margin-right: 16px;
            padding-right: 0;
            .kpi-card {
                background-color: $light-secondary;
            }
        }
    }
}
