.form-map-title {
    color: $color-school;
}

.map-side-wrapper {
    min-height: 912px;

    .widget-form-map-map {
        height: 100%;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

.form-side-wrapper {
    min-height: 912px;
    background-color: $accent-light;
    padding: 80px 60px;


    .actions {
        input {
            margin-top: 0px !important;
        }
    }

    .hbspt-form form .hs-form-field .input textarea{
        height: 104px !important;
    }


    @include media-breakpoint-down(lg) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 48px !important
    }

    .form-map-paragraph {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        a {
            color: $color-school;
        }
    }
}