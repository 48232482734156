.spacer{
    &.spacer-height-16{
        padding-top: 16px;
        @include media-breakpoint-down(md) {
            padding-top: 8px;
        }
    }
    &.spacer-height-24{
        padding-top: 24px;
        @include media-breakpoint-down(md) {
            padding-top: 8px;
        }
    }
    &.spacer-height-48{
        padding-top: 48px;
        @include media-breakpoint-down(md) {
            padding-top: 24px;
        }
    }
    &.spacer-height-64{
        padding-top: 64px;
        @include media-breakpoint-down(md) {
            padding-top: 32px;
        }
    }
    &.spacer-height-80{
        padding-top: 80px;
        @include media-breakpoint-down(md) {
            padding-top: 40px;
        }
    }
    &.spacer-height-96{
        padding-top: 96px; 
        @include media-breakpoint-down(md) {
            padding-top: 48px;
        }
    }
    &.spacer-height-104{
        padding-top: 104px; 
        @include media-breakpoint-down(md) {
            padding-top: 48px;
        } 
    }
    &.spacer-height-120{
        padding-top: 120px;  
        @include media-breakpoint-down(md) {
            padding-top: 64px;
        }
    }
    &.spacer-color-school-light{
        background-color: $color-school-light;
    }
    &.spacer-color-accent-light{
        background-color: $accent-light;
    }
    &.spacer-color-none{
        background-color: $white;
    }
}