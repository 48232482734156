// custom
$accent: #34bbda;
$black: #000000;
$color-school: #4d2777;
$color-school-light: #f5eeff;
$color-school-dark: #3e1f5f;
$blue-input-focus: #388bff;
$cultured: #f7f8f9;
$accent-light: #f5fcfd;
$light-secondary: #fefff0;
$white: #ffffff;
$direction: "ltr";
$isRTL: false;
$bright-gray: #ededed;
$checkbox-bg: #0c66e4;
$alert-form: #ae2a19;
$danger: #ae2a19;
$form-focus: #388bff;
$hover-form: #f7f8f9;

// container
$container-fluid-max-width: 1920px;

// bootstrap variables
$primary: #cdd500;
$secondary: #ffffff;
// TODO da capire se gestire tramite site
$success: #216e4e;
$warning: #ae2a19;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1440px,
);

$grid-gutter-width: 16px;

// grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 769px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$body-color: $black;

$body-bg: $white;
$body-secondary-bg: #e1e4e1;
$body-tertiary-bg: #c8cec9;

$link-color: $color-school;
$link-decoration: none;

$btn-border-radius: 32px;
$btn-color: $black;
$btn-line-height: 16px;
$btn-padding-y: 14px;
$btn-padding-x: 24px;
$btn-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$font-family-base: "Raleway";
$font-family-heading: "FigGrotesk";

$nav-link-color: $color-school;
$nav-link-hover-color: $color-school;
