.overview-countdown-widget{
    position: relative;
    padding-right:0 !important;
    padding-left: 0 !important;
    .col-event-image{
        padding: 0;
        img{
            width:100%;
            aspect-ratio: 1/1;
            height: auto;
            object-fit: cover;
            border-radius: 0 8px 8px 0;
            @include media-breakpoint-down(lg) {
                border-radius: 8px;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-bottom: 48px;
            padding: 0 16px;
        }
        &.ratio-16-9{
            img{
                aspect-ratio: 16/9;
            }
        }
        &.ratio-4-3{
            img{
                aspect-ratio:4/3;
            }
        }
    }
    .flex-lg-row-reverse{
        .col-event-image{
            img{
                width:100%;
                aspect-ratio: 1/1;
                height: auto;
                object-fit: cover;
                border-radius: 8px 0 0 8px;
                @include media-breakpoint-down(lg) {
                    border-radius: 8px;
                }
            }
            @include media-breakpoint-down(lg) {
                margin-bottom: 48px;
                padding: 0 16px;
            }
            &.ratio-16-9{
                img{
                    aspect-ratio: 16/9;
                }
            }
            &.ratio-4-3{
                img{
                    aspect-ratio:4/3;
                }
            }
        }

    }
        .container-countdown-content{
            height: 100%;
            top:0;
            z-index: 0;
            @include media-breakpoint-down(lg) {
                position: relative;
                padding: 0 16px;
            }
        .flex-lg-row-reverse{
            .column-content{
                padding-right: 104px;
                @include media-breakpoint-down(lg) {
                    padding-right: 40px;
                }
                @include media-breakpoint-down(lg) {
                    padding-right: 0px;
                }
            }  
        }  .flex-lg-row{
            .column-content{
                padding-left: 104px;
                @include media-breakpoint-down(lg) {
                    padding-left: 40px;
                }
                @include media-breakpoint-down(lg) {
                    padding-left: 0px;
                }
            }  
        }
            img{
                path{
                    fill: $color-school;
                }
            }
            .column-content{
                .label-exipred {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                }
                .countdown-date{
                    margin-bottom: 32px;
                    svg{
                        path{
                            fill: $color-school;
                        }
                    }
                    p{
                         margin-bottom:0;
                         font-size: 20px;
                        font-weight: 400;
                        margin-left: 16px;
                    }
                }
                .countdown-title{
                    .countdown-title-tag{
                font-size: 48px;
                font-weight: 700;
                line-height: 60px; 
                color: $color-school;
                margin-bottom: 8px;
                @include media-breakpoint-down(lg) {
                font-size: 36px;
                line-height: 42px; 
                }
                    }
                }
                .countdown-description{
                    p{
                        font-size: 20px;
                        line-height: 32px; 
                        margin-bottom: 42px;
                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                        line-height: 30px;
                        margin-bottom: 32px;
                    }
                    }
                }
                .countdown-content{
                    .countdown-label{
                        background-color: $color-school-light;
                        padding: 12px 48px;
                        text-transform: uppercase;
                        color: $color-school;
                        border-radius: 8px;
                        font-size: 20px;
                        line-height: 20px;
                        position: relative;
                        z-index: 1;
                        @include media-breakpoint-down(lg) {
                        font-size: 18px;
                        line-height: 30px; 
                        padding: 8px 32px;
                        }
                    }
                    .cta-container{
                        margin-top: -23px ;
                    }
                    .countdown-time{
                        background-color: $color-school;
                        padding: 40px 0px;
                        margin-top: -22px;
                        border-radius: 8px;
                       hr{
                        border: none;
                        border-left: 1px solid $white;
                        opacity: 1;
                       }
                        p{
                            color: $white;
                            text-align: center;
                            font-size: 32px;
                            font-weight: 700;
                            line-height: 42px; 
                            width: 42px;
                            margin-bottom: 0;
                        }
                        span{
                            color: $white;
                            text-align: center;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 32px;
                            letter-spacing: 1px;
                            display: block;
                            text-transform: uppercase;
                        }
                    }
                }
                .all-news-cta{
                    margin-top: 40px;
                    @include media-breakpoint-down(lg) {
                        margin-top: 56px;
                    } 
                        a{
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 0.32px;
                        color: $color-school;
                        display: flex;
                        align-items: center;
                        &::after{
                            content: '';
                            -webkit-mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                            mask: url("../../../public/images/arrow-right-color-school.svg") no-repeat 50% 50%;
                            -webkit-mask-size: cover;
                            mask-size: cover;
                            width: 7px;
                            height: 12px;
                            background-color: $color-school;
                            display: inline-block;
                            margin-left: 10px;
                            padding-top: 4px;
                            transition: .3s;
                         }
                         &:hover{
                            &::after{
                                transform: translateX(8px) !important;
                             }
                         }
                        }
                        &.button-outline-flip{
                            a{
                            &::after{
                            transform: rotate(180deg);
                            }
                            &:hover{
                                &::after{
                                    transform: rotate(180deg) translateX(-8px) !important;
                                 }
                             }
                        }
                     
                         }
                }
            }
        }
}