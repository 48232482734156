.form-title {
    color: $color-school;
}

.form-paragraph {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.widget-form-wrapper {
    max-width: 920px;
}