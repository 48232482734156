.timeline-widget{
    .swiper-button-disabled{
        svg{
            opacity: .4;
        }
    }
    .swiper-button-prev{
        cursor: pointer;
        position: absolute;
        bottom: calc(50% - 50px + 22px);
        left: 64px;
        cursor: pointer;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        z-index: 1;
        &.swiper-button-prev-row{
            left: 33.333%;
            @include media-breakpoint-down(lg) {
                left: 50%;
            }
        }
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        @media (max-width:1440px)  {
            left: 16px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        &.button-gallery-flip{
                transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        position: absolute;
        cursor: pointer;
        right: 64px;
        bottom: calc(50% - 50px + 22px);
        transform: rotate(180deg);
        z-index: 1;
        background-color: $white;
        border-radius: 50%;
        border: none;
        padding: 0;
        circle{
            stroke: $color-school;
        }
        path{
            fill: $color-school;
        }
        &.button-gallery-flip{
            transform: rotate(360deg);
        }
        @media (max-width:1440px) {
            right: 16px;
            }
        @include media-breakpoint-down(md) {
                display: none;
            }
    }
}
.widget-timeline-image-cards-wrapper {

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        padding-left: 0px !important;
        padding-right: 0px !important;
        overflow-x: hidden !important;
    }

    .widget-timeline-image-cards-container {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        .card-wrapper-margin-bottom {
            margin-bottom: 78px;
        }
    }

    .widget-timeline-image-card-wrapper {
        padding-top: 20px;
        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
            width: 100%;
        }
      
        .widget-timeline-image-icon-card {
            width: 323px;
            padding-bottom: 32px;
            border-radius: 8px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            overflow: hidden;
            transition: all 0.3s ease;
            background-color: #fff;
            &:hover{
                transform: translateY(-10px);
                box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
                .widget-timeline-image-header-icon {
                    img{
                        transform: scale(1.1);
                    }
                }
            }
            .widget-timeline-image-header-icon {
                overflow: hidden;

                img {
                    width: 323px;
                    height: 180px;
                    object-fit: cover;
                    transition: .4s;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }


            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .timeline-image-icon-card-title {
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                color: $color-school;
            }
            .timeline-image-icon-card-paragraph {
                a {
                    color: $color-school;
                }
            }
        }

        .widget-timeline-icon-image-img {
            width: 323px;
            svg {
                      position: relative;
                      z-index: 2;
                g {
                    #Vector {
                        fill: $accent;
                    }

                    #Vector_2 {
                        fill: $color-school;
                    }

                    line {
                        stroke: $accent;
                    }
                }
            }
        }

        .widget-timeline-image-icon-img-mobile {
            svg {
                position: relative;
                z-index: 1;
                g {
                    #Vector {
                        fill: $accent;
                    }

                    #Vector_2 {
                        fill: $color-school;
                    }
                }
            }
        }

        .widget-timeline-image-icon-index {
            height: 70px;
            width: 323px;
            color: $color-school;
        }

        .widget-timeline-image-icon-index-mobile {
            color: $color-school;
        }
    }

}

.widget-timeline-image-title {
    margin-bottom: 48px;
    color: $color-school;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
}

.widget-timeline-image-icon-line {
    border-bottom: solid $body-tertiary-bg 2px;
    z-index: 0;
}

.timeline-img-line-padding {
    bottom: 202px;
    z-index: 1;
    &.line-with-cta{
        bottom: 274px;
    }

}

.timeline-line-img-no-padding {
    bottom: calc(202px - 104px);
    &.line-with-cta{
        bottom: calc(274px - 104px);
    }
}