.vertical-intro {
    .intro-wrapper-padding {
        padding: 104px 0px;

        @include media-breakpoint-down(md) {
            padding: 48px 0px;
        }
    }

    .intro-logo-wrapper {
        margin-bottom: 32px;
    }
    .intro-title{
        color: $color-school;
    }
    .intro-paragraph img {
        max-width: 100%;
    }
    .intro-paragraph p {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 30px;
        }
        a {
            color: $color-school;
        }
    }

    .intro-cta-wrapper {
        gap: 16px;
        @include media-breakpoint-down(md) {
            gap: 12px;
            margin-right: 0px;

            .btn-primary {
                margin-right: 0px !important;
            }
        }
    }
}